import { createSelector } from "reselect";

import { INFO_DATA_KEY } from "./info-data-constants";
import { InfoDataState } from "./info-data-types";

// eslint-disable-next-line
export const getStateInfoData = (appState: any) => appState[INFO_DATA_KEY];

export const getInfoDepartments = createSelector(getStateInfoData, (state: InfoDataState) => state.departments);

export const getInfoFarms = createSelector(getStateInfoData, (state: InfoDataState) => state.farms);
export const getInfoPositions = createSelector(getStateInfoData, (state: InfoDataState) => state.positions);

export const getInfoSeasons = createSelector(getStateInfoData, (state: InfoDataState) => state.seasons);

export const getInfoWorkPlace = createSelector(getStateInfoData, (state: InfoDataState) => state.workPlace);

export const getInfoCropTypes = createSelector(getStateInfoData, (state: InfoDataState) => state.cropTypes);

export const getInfoTechTypes = createSelector(getStateInfoData, (state: InfoDataState) => state.techTypes);

export const getInfoTechOperationGroupName = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationGroup
);
export const getInfoTechOperationSubGroupName = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationSubGroup
);

export const getTechAssetCategories = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techAssetCategory
);

export const getInfoRoles = createSelector(getStateInfoData, (state: InfoDataState) => state.roles);
export const getInfoPhenoPhases = createSelector(getStateInfoData, (state: InfoDataState) => state.phenoPhases);
export const getTechOperationsScalars = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationsScalars
);
