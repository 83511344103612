import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  control: {
    position: "absolute",
    zIndex: 99999,
    width: "100%",
    bottom: 0,
  },
  progress: {
    height: 20,
  },
  progressTitle: {
    position: "absolute",
    zIndex: 99999,
    width: "100%",
    bottom: 0,
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
  },
}));
