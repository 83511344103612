import { useTheme } from "@material-ui/core";
import { Feature, GeometryObject } from "geojson";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { getList } from "../../../../modules/crop/store/crops.selector";
import { TArcgisBriefCropPropsDto } from "../dtos/arcgis-brief-crop-props.dto";

// eslint-disable-next-line
export const useCropStyles = () => {
  const crops = useSelector(getList);
  const theme = useTheme();
  const colors = useMemo(
    () =>
      crops.reduce((acc, item) => {
        acc[item.name] = item.color;
        return acc;
      }, {} as Record<string, string>),
    [crops]
  );

  const style = useCallback(
    (feature?: Feature<GeometryObject, TArcgisBriefCropPropsDto>) => {
      if (!feature) {
        return {};
      }

      return {
        color: theme.palette.text.primary,
        weight: 2,
        fillColor: colors[feature.properties.crop] || theme.palette.info.main,
        fillOpacity: 0.7,
      };
    },
    // eslint-disable-next-line
    [colors]
  );

  return { style };
};
