import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import lodashGet from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IProps {
  name: string;
  DateTimePickerProps?: Partial<DateTimePickerProps>;
}

export const RHFDateTimePicker = (props: IProps): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, props.name)),
    [props, rhfMethods.formState.errors]
  );

  const renderDateTimePicker = useCallback(
    ({ onChange, value }) => (
      <DateTimePicker
        value={value}
        onChange={(newValue) => {
          onChange(new Date(newValue as Date).getTime());
        }}
        fullWidth={true}
        cancelLabel={"Отменить"}
        okLabel={"Подтвердить"}
        format="dd/MM/yyyy HH:mm"
        ampm={false}
        hideTabs={true}
        animateYearScrolling={true}
        error={error}
        invalidDateMessage={"Некорректный формат даты. Ожидаемый формат дд/мм/гггг чч:мм"}
        minDateMessage={
          props.DateTimePickerProps?.minDate && `Дата не должна быть раньше ${props.DateTimePickerProps?.minDate}`
        }
        maxDateMessage={
          props.DateTimePickerProps?.maxDate && `Дата не должна быть позднее ${props.DateTimePickerProps?.maxDate}`
        }
        {...props.DateTimePickerProps}
      />
    ),
    [error, props]
  );

  return <Controller name={props.name} control={rhfMethods.control} render={renderDateTimePicker} />;
};
