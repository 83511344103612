import React from "react";

import { LoginFormDataTypes } from "../common-types";
import { API_URL } from "../config/api";
import { logAction } from "../modules/errors";
import { useAppDispatch } from "../store";
import { authContext } from "./authContext";
import { checkAccessTokenAPI } from "./checkAccessToken";

// eslint-disable-next-line
export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();

  if (auth.isAuthenticated === null) {
    checkAccessTokenAPI().then(auth.userHasAuthenticated);
    return null;
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useProvideAuth() {
  const [isAuthenticated, userHasAuthenticated] = React.useState<boolean | null>(null);
  const dispatch = useAppDispatch();

  const login = async (data: LoginFormDataTypes) => {
    const url = new URL(`${API_URL}/AppUsers/login`);

    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      if (!response.ok) return false;

      const jsonResponse = await response.json();
      if (!jsonResponse.id) return false;

      localStorage.setItem("access_token", jsonResponse.id);
      localStorage.setItem("user_id", jsonResponse.userId);

      const fetchUserUrl = new URL(
        `${API_URL}/AppUsers/${jsonResponse.userId}?access_token=${jsonResponse.id}`
      ).toString();

      const fetchDefaultFarmUrl = new URL(
        `${API_URL}/AppUsers/${jsonResponse.userId}/defaultFarm?access_token=${jsonResponse.id}`
      ).toString();

      const userDataFetchRequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      await Promise.all([
        fetch(fetchUserUrl, userDataFetchRequestInit).then(async (result) => {
          const jsonUserResponse = await result.json();
          if (result.ok) {
            localStorage.setItem("firstName", jsonUserResponse.firstName);
            localStorage.setItem("lastName", jsonUserResponse.lastName);
            localStorage.setItem("positionName", jsonUserResponse.positionName);
          }
        }),
        await fetch(fetchDefaultFarmUrl, userDataFetchRequestInit).then(async (result) => {
          const jsonDefaultFarmResponse = await result.json();
          if (result.ok) {
            localStorage.setItem("defaultFarmName", jsonDefaultFarmResponse.name);
          }
        }),
      ]);

      userHasAuthenticated(true);
      return true;
    } catch {
      dispatch(logAction(`Ошибка получения токена.`));
      return false;
    }
  };

  const logout = async () => {
    // TODO
    // const userId = localStorage["user_id"];
    const tokenAccess = localStorage["access_token"];

    const url = new URL(`${API_URL}/AppUsers/logout?access_token=${tokenAccess}`);

    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 204) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("positionName");
        localStorage.removeItem("defaultFarmName");
        userHasAuthenticated(false);
      }

      return true; // TODO
    } catch {
      dispatch(logAction(`Ошибка удаления токена.`));
      return false;
    }
  };

  return {
    login,
    logout,
    isAuthenticated,
    userHasAuthenticated,
  };
}
