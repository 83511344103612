import { FormControlLabel, Grid, ListItem, Switch } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { ITechAssetsWithIncludeDto } from "../../../../modules/tech-assets/shared/dtos/tech-assets";
import { TechAssetWithInclude } from "../../../../modules/tech-assets/shared/models/tech-assets";
import { getList as getTechAssetsList } from "../../../../modules/tech-assets/store";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { TechAssetType } from "../../../../shared/enums/tech-asset-type";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";

interface IProps {
  isEditingTechOperation: boolean;
}

export const TechOperationEditingTechAssets = (props: IProps): JSX.Element => {
  const rhfMethods = useFormContext();
  const watchFields = rhfMethods.watch();

  const appFilterSeasonId = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const techAssets = useSelector(getTechAssetsList) as TechAssetWithInclude[];

  const [techAssetAllowAllFarms, setTechAssetAllowAllFarms] = useState(false);
  const [techAssetAllowAllGroups, setTechAssetAllowAllGroups] = useState(false);

  useEffect(() => {
    (async () => {
      if (!watchFields.farmId || !appFilterSeasonId || !props.isEditingTechOperation) {
        return;
      }

      setTechAssetAllowAllFarms(true);
      setTechAssetAllowAllGroups(true);
    })();
  }, [watchFields.farmId, appFilterSeasonId, props.isEditingTechOperation]);

  const availableTechAssets = useMemo(() => {
    if (!techAssets?.length) {
      return [];
    }

    return techAssets?.filter(
      (item) =>
        (item.category === TechAssetType.TECH || item.category === TechAssetType.BOTH) &&
        (techAssetAllowAllFarms || item.farms?.find((farm) => farm.id === watchFields.farmId)) &&
        (techAssetAllowAllGroups ||
          item.techOperationSubGroups?.find((group) => group.id === watchFields.techOperationSubGroupId))
    );
  }, [
    techAssets,
    techAssetAllowAllFarms,
    techAssetAllowAllGroups,
    watchFields.farmId,
    watchFields.techOperationSubGroupId,
  ]);

  const availableTrailerAssets = useMemo(() => {
    if (!techAssets) {
      return [];
    }

    const res = techAssets.filter(
      (item) =>
        (item.category === TechAssetType.TRAILER || item.category === TechAssetType.BOTH) &&
        (techAssetAllowAllFarms || item.farms?.find((farm) => farm.id === watchFields.farmId)) &&
        (techAssetAllowAllGroups ||
          item.techOperationSubGroups?.find((group) => group.id === watchFields.techOperationSubGroupId))
    );
    res.unshift({
      id: "-1",
      name: "Без прицепной техники",
    } as unknown as TechAssetWithInclude);
    return res;
  }, [
    techAssets,
    techAssetAllowAllFarms,
    techAssetAllowAllGroups,
    watchFields.farmId,
    watchFields.techOperationSubGroupId,
  ]);

  return (
    techAssets && (
      <ListItem>
        <Grid container={true} spacing={2} alignItems={"center"}>
          <Grid item={true} xs={4}>
            <RHFAutocomplete<ITechAssetsWithIncludeDto>
              name="techAssetId"
              rules={{ required: true }}
              renderValue={(value) => findModelByProperty(availableTechAssets, value)}
              AutocompleteProps={{
                disabled: !watchFields.farmLandId,
                options: availableTechAssets,
                noOptionsText: "Самоходной техники не найдено",
              }}
              TextFieldProps={{
                label: "Самоходная техника",
              }}
            />
          </Grid>

          <Grid item={true} xs={4}>
            <RHFAutocomplete<ITechAssetsWithIncludeDto>
              name="trailerAssetId"
              rules={{ required: true }}
              renderValue={(value) => findModelByProperty(availableTrailerAssets, value)}
              AutocompleteProps={{
                disabled: !watchFields.farmLandId,
                options: availableTrailerAssets,
                noOptionsText: "Прицепной техники не найдено",
              }}
              TextFieldProps={{
                label: "Прицепная техника",
              }}
            />
          </Grid>

          <Grid item={true}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => setTechAssetAllowAllFarms(evt.target.checked)}
                  checked={techAssetAllowAllFarms}
                  color={"primary"}
                />
              }
              label="Из всех хозяйств"
            />
          </Grid>

          <Grid item={true}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => setTechAssetAllowAllGroups(evt.target.checked)}
                  checked={techAssetAllowAllGroups}
                  color={"primary"}
                />
              }
              label="Вся техника"
            />
          </Grid>
        </Grid>
      </ListItem>
    )
  );
};
