import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IObservationEditingState } from "../shared/interfaces/observation-editing-state";
import { MODULE_NAME, observationEditingSlice } from "./observation-editing.slice";

//eslint-disable-next-line
export const getObservationPhotosState = (rootState: any): IObservationEditingState => rootState[MODULE_NAME];

export const getObservationData = createDraftSafeSelector(
  getObservationPhotosState,
  (state: IObservationEditingState) => state.observation
);

export const getOldPhotosList = createDraftSafeSelector(
  getObservationPhotosState,
  (state: IObservationEditingState) => state.oldPhotos
);

export const getChosenPhotosList = createDraftSafeSelector(
  getObservationPhotosState,
  (state: IObservationEditingState) => state.chosenPhotos
);

export const getPhotosDirty = createDraftSafeSelector(getObservationPhotosState, (state: IObservationEditingState) => {
  const initialState = observationEditingSlice.getInitialState();
  return initialState.oldPhotos.length !== state.oldPhotos.length || Boolean(state.chosenPhotos.length);
});
