export enum ArcgisCropField {
  season = "season", // Сезон
  farm = "farm", // Хозяйство
  subdivision = "subdivision", // Подразделение
  name = "name", // Полный номер поля
  area_2d = "area_2d", // Площадь (га)
  crop = "crop", // Культура
  crop_shortname = "crop_shortname", // Культура (сокращенное название)
  sort = "sort", // Сорт / гибрид
  reproduction = "reproduction", // Репродукция высеваемых семян
  seeds_orig = "seeds_orig", // Происхождение семян
  products_purp = "products_purp", // Назначение продукции в текущем году
  cult_tech = "cult_tech", // Технология выращивания
  age = "age", // Год жизни многолетних культур
  score = "score", // Балл плодородия
  comment = "comment", // Комментарий
  sowing_start = "sowing_start", // Дата начала сева
  sowing_end = "sowing_end", // Дата конца сева
  harvesting_start = "harvesting_start", // Дата начала уборки
  harvesting_end = "harvesting_end", // Дата конца уборки
  last_edited_date = "last_edited_date", // last_edited_date
  guid_name = "guid_name", // guid_name
  GDB_ARCHIVE_OID = "GDB_ARCHIVE_OID", // GDB_ARCHIVE_OID
  OBJECTID = "OBJECTID", // GDB_ARCHIVE_OID
}
