import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFarmsState } from "../shared";
import { MODULE_NAME } from "./farms.slice";

// eslint-disable-next-line
export const getFarmsState = (rootState: any): IFarmsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getFarmsState, (state: IFarmsState) => state.isLoading);
export const getList = createDraftSafeSelector(getFarmsState, (state: IFarmsState) => state.list);
