import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getCropsList } from "../../../../modules/crop";
import { getCropTypesList } from "../../../../modules/crop-types";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import {
  getInfoSeasons,
  getInfoTechOperationGroupName,
  getInfoTechOperationSubGroupName,
} from "../../../../modules/info-data";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFDatePicker } from "../../../../shared/components/react-hook-form-mui/datepicker";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { isEditingPage } from "../../../../shared/utils/is-editing-page";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import { fetchFarmLandsByFarmId } from "../../../fields/store/farm-lands.slice";
import { TechOperationEditingEmployees } from "../../components/tech-operation-editing-employees/tech-operation-editing-employees";
import { TechOperationEditingSubgroupParams } from "../../components/tech-operation-editing-subgroup-params/tech-operation-editing-subgroup-params";
import { TechOperationEditingTechAssets } from "../../components/tech-operation-editing-techassets/tech-operation-editing-techassets";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOperation } from "../../shared/models/tech-operation";
import { TechOperationAsset } from "../../shared/models/tech-operation-asset";
import { getAssets } from "../../store/tech-operation-editing.selector";
import {
  addTechOperationAction,
  fetchTechOperationByIdAction,
  updateTechOperationAction,
} from "../../store/tech-operation-editing.slice";
import {
  fetchTechOperationByFarmLandAndTechOperationGroupIdsAndOperationNumberAction,
  fetchTechOperationSubGroupIdsByCropTypeId,
  getMaxOperationNumbersByFarmLandAndTechOperationGroupIdsAction,
} from "../../store/tech-operations.slice";
import { TechOperationEditingAssets } from "../tech-operation-editing-assets/tech-operation-editing-assets";

export const TechOperationsEditingPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const sourceFarmLandId = query.get("fieldId");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState<{
    type: AlertProps["severity"];
    text: JSX.Element | string;
  } | null>(null);
  const appFilterFarmId = useSelector((state) => getFilter(state, FilterName.FarmId));
  const appFilterSeasonId = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const [fieldSizeAvailable, setFieldSizeAvailable] = useState(0);
  const seasons = useSelector(getInfoSeasons);
  const [seasonDates, setSeasonDates] = useState<{
    startedAt?: number;
    finishedAt?: number;
  }>({});
  const techOperationsGroups = useSelector(getInfoTechOperationGroupName);
  const techOperationsSubGroups = useSelector(getInfoTechOperationSubGroupName);
  const cropTypes = useSelector(getCropTypesList);
  const crops = useSelector(getCropsList);
  const assets = useSelector(getAssets);
  const [availableTechOperationSubGroupIds, setAvailableTechOperationSubGroupIds] = useState<string[]>([]);

  const [farmLands, setFarmLands] = useState<IFarmLandDto[]>([]);
  const [availableOperationNumbers, setAvailableOperationNumbers] = useState<{ name: string; id: number }[]>([]);
  const [isOperationNumberExist, setOperationNumberExist] = useState<boolean>(true);
  const [defaultValues, setDefaultValues] = useState(new TechOperation().setFarmLandId(sourceFarmLandId).asFormData);

  const rhfMethods = useForm<ITechOperationFormData>({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
  });

  const watchFields = rhfMethods.watch();

  // Utility getters

  const getSeasonDates = useCallback(
    (seasonId) => {
      const currentSeason = findModelByProperty(seasons, seasonId);
      setSeasonDates({
        startedAt: currentSeason?.startedAt,
        finishedAt: currentSeason?.finishedAt,
      });
    },
    [seasons]
  );

  // Update lists

  const updateFarmLandsList = async (farmId, seasonId) => {
    const list = await dispatch(
      fetchFarmLandsByFarmId({
        farmId,
        excludeGeometry: true,
        filter: {
          where: {
            seasonId,
            isDeleted: false,
          },
        },
      })
    ).unwrap();
    list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
    setFarmLands(list);
  };

  useEffect(() => {
    getSeasonDates(appFilterSeasonId);
  }, [appFilterSeasonId, getSeasonDates]);

  const isEditingTechOperation = useMemo(() => isEditingPage(id), [id]);

  useEffect(() => {
    (async () => {
      if (!appFilterFarmId || !appFilterSeasonId) {
        return;
      }

      updateFarmLandsList(appFilterFarmId, appFilterSeasonId);

      if (!isEditingTechOperation) {
        const timestamp = new Date().getTime();
        setDefaultValues((prevValues) => ({
          ...prevValues,
          startedAt: timestamp,
          finishedAt: timestamp,
          farmId: appFilterFarmId,
          seasonId: appFilterSeasonId,
        }));

        return;
      }

      if (id) {
        const model = await dispatch(fetchTechOperationByIdAction(id)).unwrap();
        setDefaultValues(model.asFormData);

        if (model.seasonId) {
          getSeasonDates(model.seasonId);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFilterFarmId, appFilterSeasonId, id]);

  useEffect(() => {
    const farmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    const currentCrop = crops.find((item) => item.id === farmLand?.cropId);
    const currentCropType = cropTypes.find((item) => item.id === farmLand?.cropTypeId);

    rhfMethods.setValue("CropType_basicHumidity", currentCrop?.basicHumidity || currentCropType?.basicHumidity || 0);
    rhfMethods.setValue("CropType_basicImpurity", currentCrop?.basicImpurity || currentCropType?.basicImpurity || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropTypes, crops, farmLands, watchFields.farmLandId]);

  useEffect(() => {
    setDefaultValues((data) => {
      const newModel = {
        ...data,
        assets: assets.map((item) => item.asFormData),
      };

      return newModel;
    });
  }, [assets]);

  const onSubmit = useCallback(
    async (data: ITechOperationFormData) => {
      const newDto = new TechOperation(data.id);
      newDto.updateFromFormData(data);
      newDto.setAppUserId(localStorage.getItem("user_id") as string);

      const assetsListNew =
        data.assets?.map((asset) => {
          const assetModel = new TechOperationAsset(asset._id);
          assetModel.updateFromFormData(asset).setFarmId(appFilterFarmId).setTechOperationId(newDto.id);
          return assetModel;
        }) || null;
      newDto.setAssets(assetsListNew);

      try {
        const model = await dispatch(
          isEditingTechOperation ? updateTechOperationAction(newDto.asDto) : addTechOperationAction(newDto.asDto)
        ).unwrap();

        const newModel = model.asFormData;
        setDefaultValues(newModel);

        if (!isEditingTechOperation) {
          navigate(`../${model.id}`, { relative: "path", replace: true });
        }
      } catch (err) {
        throw err;
      }
    },
    [appFilterFarmId, dispatch, navigate, isEditingTechOperation]
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onMessageHide = useCallback((_, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage(null);
  }, []);

  useEffect(() => {
    (async () => {
      if (!farmLands.length || !watchFields.farmLandId) {
        return;
      }

      const farmLand = findModelByProperty(farmLands, watchFields.farmLandId);

      if (rhfMethods.formState.touched.farmLandId || rhfMethods.formState.dirtyFields.farmLandId) {
        rhfMethods.formState.touched.farmLandId = true;
        rhfMethods.setValue("cropTypeId", farmLand?.cropTypeId);
        rhfMethods.setValue("operationNumber", "");
        rhfMethods.setValue("techOperationGroupId", "");
        rhfMethods.setValue("techOperationSubGroupId", "");
      }
    })();
    // eslint-disable-next-line
  }, [farmLands, watchFields.farmLandId]);

  useEffect(() => {
    if (!watchFields.cropTypeId) {
      return;
    }
    dispatch(fetchTechOperationSubGroupIdsByCropTypeId(watchFields.cropTypeId))
      .unwrap()
      .then((result) => {
        setAvailableTechOperationSubGroupIds(result || []);
      });
  }, [dispatch, watchFields.cropTypeId]);

  useEffect(() => {
    if (!watchFields.farmLandId || !watchFields.operationNumber) {
      setFieldSizeAvailable(0);
      return;
    }

    let fieldSizeDone = 0;
    dispatch(
      fetchTechOperationByFarmLandAndTechOperationGroupIdsAndOperationNumberAction({
        farmLandId: watchFields.farmLandId,
        techOperationGroupId: watchFields.techOperationGroupId,
        operationNumber: watchFields.operationNumber,
      })
    )
      .unwrap()
      .then((result) => {
        fieldSizeDone = result
          .filter((item) => item.seasonId === watchFields.seasonId)
          .reduce((acc, item) => (item.id === watchFields.id ? acc : acc + (item?.fieldSize || 0)), 0);
      })
      .finally(() => {
        const fieldSizeTotal = findModelByProperty(farmLands, watchFields.farmLandId)?.area || 0;
        setFieldSizeAvailable(fieldSizeTotal - fieldSizeDone);
      });
  }, [
    dispatch,
    farmLands,
    watchFields.farmLandId,
    watchFields.techOperationGroupId,
    watchFields.operationNumber,
    watchFields.id,
    watchFields.seasonId,
  ]);

  useEffect(() => {
    (async () => {
      if (!watchFields.operationNumber || watchFields.operationNumber === availableOperationNumbers[0]?.id) {
        rhfMethods.setValue("techOperationSubGroupId", "");

        setOperationNumberExist(false);

        return;
      }

      let techOperationSubGroupId = "";

      dispatch(
        fetchTechOperationByFarmLandAndTechOperationGroupIdsAndOperationNumberAction({
          farmLandId: watchFields.farmLandId,
          techOperationGroupId: watchFields.techOperationGroupId,
          operationNumber: watchFields.operationNumber,
        })
      )
        .unwrap()
        .then((result) => {
          techOperationSubGroupId = result[0]?.techOperationSubGroupId || "";
        })
        .finally(() => {
          rhfMethods.setValue("techOperationSubGroupId", techOperationSubGroupId);

          setOperationNumberExist(Boolean(techOperationSubGroupId));
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, availableOperationNumbers, watchFields.operationNumber]);

  useEffect(() => {
    rhfMethods.setValue("techOperationSubGroupId", "");
    rhfMethods.setValue("operationNumber", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFields.techOperationGroupId]);

  const availableTechOperationsSubGroups = useMemo(() => {
    return techOperationsSubGroups.filter((item) => {
      return item.parentId === watchFields.techOperationGroupId && availableTechOperationSubGroupIds.includes(item.id);
    });
  }, [availableTechOperationSubGroupIds, techOperationsSubGroups, watchFields.techOperationGroupId]);

  const currentTechOperationSubGroup = useMemo(() => {
    return findModelByProperty(techOperationsSubGroups, watchFields.techOperationSubGroupId);
  }, [techOperationsSubGroups, watchFields.techOperationSubGroupId]);

  useEffect(() => {
    if (!watchFields.farmLandId || !watchFields.techOperationGroupId) {
      setAvailableOperationNumbers([]);
      return;
    }

    //eslint-disable-next-line
    const res: any[] = [];
    dispatch(
      getMaxOperationNumbersByFarmLandAndTechOperationGroupIdsAction({
        farmLandId: watchFields.farmLandId,
        techOperationGroupId: watchFields.techOperationGroupId,
      })
    )
      .unwrap()
      .then((result) => {
        for (let i = 1; i <= result; i++) {
          res.unshift({
            name: i.toString(),
            id: i,
          });
        }

        res.unshift({
          name: "Добавить новый",
          id: result + 1,
        });

        setAvailableOperationNumbers(res);
      })
      .catch((_) => {
        setAvailableOperationNumbers([]);
      });
  }, [dispatch, watchFields.farmLandId, watchFields.techOperationGroupId]);

  const isSubmitDisabled = useMemo(() => {
    return !rhfMethods.formState.isDirty;
  }, [rhfMethods.formState]);

  useEffect(() => {
    rhfMethods.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const chosenFarmLandCropName = useMemo(() => {
    const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    const chosenFarmLandCrop = findModelByProperty(cropTypes, chosenFarmLand?.cropTypeId);
    return chosenFarmLandCrop?.name || "";
  }, [cropTypes, farmLands, watchFields.farmLandId]);

  return (
    <CommonLayout title={"Техоперации"}>
      <Paper>
        <FormProvider {...rhfMethods}>
          <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
            <RHFInputHidden name="id" />
            <RHFInputHidden name="farmId" />
            <RHFInputHidden name="seasonId" />
            <RHFInputHidden name="cropTypeId" />
            <RHFInputHidden name="CropType_basicHumidity" defaultValue={""} />
            <RHFInputHidden name="CropType_basicImpurity" defaultValue={""} />

            <List>
              <ListItem>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <RHFAutocomplete<IFarmLandDto>
                      name="farmLandId"
                      rules={{ required: true }}
                      renderValue={(value) => findModelByProperty(farmLands, value)}
                      AutocompleteProps={{
                        disabled: !farmLands.length || Boolean(sourceFarmLandId) || isEditingTechOperation,
                        options: farmLands,
                        noOptionsText: "В хозяйстве нет полей",
                      }}
                      TextFieldProps={{
                        label: "Поле",
                      }}
                    />
                  </Grid>

                  <Grid item={true}>
                    <Box pt={3}>{chosenFarmLandCropName}</Box>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <RHFAutocomplete
                  name="techOperationGroupId"
                  rules={{ required: true }}
                  renderValue={(value) => findModelByProperty(techOperationsGroups, value)}
                  AutocompleteProps={{
                    disabled: !techOperationsGroups.length || isEditingTechOperation,
                    options: techOperationsGroups,
                    noOptionsText: "Нет доступных типов операций",
                  }}
                  TextFieldProps={{
                    label: "Тип операции",
                  }}
                />
              </ListItem>

              <ListItem>
                <RHFAutocomplete
                  name="operationNumber"
                  rules={{ required: true }}
                  renderValue={(value) => findModelByProperty(availableOperationNumbers, value)}
                  AutocompleteProps={{
                    disabled: !watchFields.farmLandId || !watchFields.techOperationGroupId || isEditingTechOperation,
                    options: availableOperationNumbers,
                    noOptionsText: "Нет доступных номеров операций",
                  }}
                  TextFieldProps={{
                    label: "Номер операции",
                  }}
                />
              </ListItem>

              <ListItem>
                <RHFAutocomplete
                  name="techOperationSubGroupId"
                  rules={{ required: true }}
                  renderValue={(value) => findModelByProperty(techOperationsSubGroups, value)}
                  AutocompleteProps={{
                    disabled: !watchFields.techOperationGroupId || isOperationNumberExist,
                    options: availableTechOperationsSubGroups,
                    noOptionsText: "Нет доступных подтипов операций",
                    getOptionLabel: (option) => option.name,
                  }}
                  TextFieldProps={{
                    label: "Подтип операции",
                  }}
                />
              </ListItem>
              {!watchFields.techOperationSubGroupId ? (
                <></>
              ) : (
                <>
                  <Box px={2} pt={4}>
                    <Typography variant={"h5"}>Настройки звена</Typography>
                  </Box>

                  <TechOperationEditingTechAssets isEditingTechOperation={isEditingTechOperation} />

                  <TechOperationEditingEmployees isEditingTechOperation={isEditingTechOperation} />

                  <ListItem>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12} sm={4}>
                        <RHFDatePicker
                          name="startedAt"
                          DatePickerProps={{
                            minDate: seasonDates?.startedAt,
                            maxDate: seasonDates?.finishedAt,
                            label: "Начало работы",
                          }}
                        />
                      </Grid>

                      <Grid item={true} xs={12} sm={4}>
                        <RHFDatePicker
                          name="finishedAt"
                          DatePickerProps={{
                            minDate: seasonDates?.startedAt,
                            maxDate: seasonDates?.finishedAt,
                            label: "Окончание работы",
                          }}
                        />
                      </Grid>

                      <Grid item={true} xs={12} sm={4}>
                        <RHFTextField
                          name="fieldSize"
                          rules={{
                            required: true,
                            min: 0,
                            ...(fieldSizeAvailable && {
                              max: fieldSizeAvailable,
                            }),
                          }}
                          TextFieldProps={{
                            type: "number",
                            label: `Завершенная площадь (остаток: ${_.floor(fieldSizeAvailable, 2)} га)`,
                            inputProps: {
                              min: 0,
                              ...(fieldSizeAvailable && {
                                max: fieldSizeAvailable,
                              }),
                              step: 0.001,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>

                  <TechOperationEditingSubgroupParams isEditingTechOperation={isEditingTechOperation} />

                  <TechOperationEditingAssets
                    techOperationId={id}
                    techOperationSubGroup={currentTechOperationSubGroup}
                  />
                </>
              )}
            </List>

            <Divider />

            <Box p={1}>
              <Grid container={true} justify={"center"} spacing={2}>
                <Grid item={true}>
                  <Button type="submit" color={"primary"} variant={"contained"} disabled={isSubmitDisabled}>
                    Сохранить
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button color={"primary"} variant={"outlined"} onClick={onCancel}>
                    Отмена
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>

        {message && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={onMessageHide}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={message.type}>{message.text}</Alert>
          </Snackbar>
        )}
      </Paper>
    </CommonLayout>
  );
};
