import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";

import { InputLoginFormTypes, InputLoginProps } from "./login-page-types";
import "./login-page.scss";

export const InputPassword = (props: InputLoginProps): JSX.Element => {
  const { inputRef, values, setValues } = props;

  const handleChange = (prop: keyof InputLoginFormTypes) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl className="input-login">
      <InputLabel required htmlFor="password">
        Пароль
      </InputLabel>
      <Input
        required
        id="password"
        inputRef={inputRef}
        name="password"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Показать пароль"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility color="primary" /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
