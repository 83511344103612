import React, { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../../authContext";

interface IProps {
  redirectPath?: string;
}

export const ProtectedRoute = (props: PropsWithChildren<IProps>): JSX.Element => {
  const { redirectPath = "/login", children } = props;
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>children</> : <Outlet />;
};
