import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const dialogWindowStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      color: theme.palette.primary.main,
      border: "2px solid",
    },
    error: {
      color: theme.palette.error.main,
      border: "2px solid",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    btnClose: {
      padding: 0,
    },
    dialogActions: {
      padding: "20px",
    },
  })
);
