import { IFarmNameTuple } from "../interfaces/farm-name-tuple";

interface ConstructorParams {
  id: string;
  nameArcgis: string;
  nameEkocrop: string;
}

export class FarmNameTuple implements IFarmNameTuple {
  readonly id: string;
  nameArcgis: string;
  nameEkocrop: string;

  constructor({ id, nameArcgis, nameEkocrop }: ConstructorParams) {
    this.id = id;
    this.nameArcgis = nameArcgis;
    this.nameEkocrop = nameEkocrop;
  }
}
