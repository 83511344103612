import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFarmLandsWeatherState } from "../shared/interfaces/farm-lands-weather-state";
import { MODULE_NAME } from "./farm-lands-weather.slice";

// eslint-disable-next-line
export const getFarmLandsWeatherState = (rootState: any): IFarmLandsWeatherState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.isLoading
);
export const getCharts = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.charts
);

export const getFilter = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.filter
);
export const getStationId = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.filter.stationId
);
export const getPeriod = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.filter.period
);
export const getFrom = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.filter.from
);
export const getTo = createDraftSafeSelector(
  getFarmLandsWeatherState,
  (state: IFarmLandsWeatherState) => state.filter.to
);
