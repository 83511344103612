import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IMapPageState } from "../shared/interfaces/map-page-state";
import { MODULE_NAME } from "./map-page.slice";

// eslint-disable-next-line
export const getMapPageState = (appState: any): IMapPageState => appState[MODULE_NAME];

export const getIsLoading = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.isLoading);

export const getFarmNameTuples = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.farmNameTuples
);

export const getSelectedCropNames = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.selectedCropNames
);

export const getZoom = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.zoom);

export const getCenter = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.center);

export const getBounds = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.bounds);

export const getGeoJson = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.geoJson);

export const getArcgisFarmLandsData = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.farmLandsData
);

export const getHighlightedFarmLandId = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.highlightedFarmLandId
);

export const getIsDrawerOpen = createDraftSafeSelector(getMapPageState, (state: IMapPageState) => state.isDrawerOpen);
