import { Box, Divider, Fade, Menu, MenuItem, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useState } from "react";

import { useAuth } from "../../authentication";
import { FilterFarmAndSeason } from "../filter/shared/containers/filter-farm-and-season/filter-farm-and-season";
import { sidebarStyles } from "./user-panel-style";

export const UserPanel = (): JSX.Element => {
  const auth = useAuth();

  const classes = sidebarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenMenu = Boolean(anchorEl);

  const logoutClickHandler = () => {
    auth.logout();
  };

  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div className={classes.userInfoRoot}>
        <div className={classes.defaultFarm}>
          <FilterFarmAndSeason />
          {/*{localStorage["defaultFarmName"]}*/}
        </div>
      </div>

      <AccountCircle className={classes.userIcon} onClick={handleClick} />

      <Menu
        style={{ inset: "42px 0px 0px" }}
        anchorEl={anchorEl}
        open={isOpenMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
        // eslint-disable-next-line
        autoFocus={false}
      >
        <MenuItem button={false}>
          <Box>
            {localStorage["firstName"]} {localStorage["lastName"]}
            <Typography component="div" variant="caption">
              {localStorage["positionName"]}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          id={"logout=menu-item"}
          selected={false}
          // eslint-disable-next-line
          autoFocus={false}
          onClick={logoutClickHandler}
          className={classes.menuItem}
        >
          <ExitToApp className={classes.menuItemIcon} />
          Выход
        </MenuItem>
      </Menu>
    </div>
  );
};
