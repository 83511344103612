import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services/base.service";
import { IObservationWithIncludesDto } from "../dtos/observations-with-includes.dto";
import { IObservationDto } from "../dtos/observations.dto";
import { IObservationsInclude } from "../interfaces/observations-include";

const includes: IObservationsInclude = {
  crop: ["name"],
  appUser: ["fullName"],
  farm: ["name"],
  farmLand: ["name"],
  phenoPhase: ["name"],
};

class ObservationsService extends BaseService<IObservationDto, ILoopbackFilter> {
  private path = "Observations";
  private photosPath = "ObservationPhotos";

  async get(id: string): Promise<IObservationDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(filter: ILoopbackFilter, options: {signal?: AbortSignal} ={}): Promise<IObservationWithIncludesDto[]> {
    const {signal = null} = options;
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
      signal,
    });
  }

  async listCount(filter: ILoopbackFilter, options: {signal?: AbortSignal} ={}): Promise<{ count: number }> {
    const {signal = null} = options;
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: filter as Record<string, unknown>,
      signal,
    });
  }

  //eslint-disable-next-line
  async add(data): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async update(data): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      include: Object.keys(includes).map((item) => {
        return {
          relation: item,
          scope: { fields: includes[item] },
        };
      }),
      ...filter,
    };
  }

  //eslint-disable-next-line
  async getObservationPhotosIds(observationsIds: string[]): Promise<any> {
    return this.fetch(this.photosPath, {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            or: observationsIds.map((id) => ({
              observationId: id,
            })),
          },
        },
      },
    });
  }

  // TODO: type promise
  //eslint-disable-next-line
  async getObservationPhotoById(id: string): Promise<any> {
    return this.fetch([this.photosPath, id, "getPhotoImage"].join("/"), {
      method: "GET",
    });
  }

  //eslint-disable-next-line
  async uploadPhotos(observationId: string, files: File[]): Promise<any> {
    const data = new FormData();
    files.forEach((file) => {
      data.append("file", file);
    });

    return this.fetch([this.path, observationId, "uploadPhotos"].join("/"), {
      method: "POST",
      body: data,
    });
  }

  //eslint-disable-next-line
  async deletePhoto(id: string): Promise<any> {
    return this.fetch([this.photosPath, id].join("/"), {
      method: "DELETE",
    });
  }

  //eslint-disable-next-line
  async getWeedViolationsByCropTypeId(id: string | undefined): Promise<any> {
    if (!id) {
      return [];
    }

    return this.fetch(["CropTypes", id, "weeds"].join("/"), { method: "GET" });
  }

  //eslint-disable-next-line
  async getDiseaseViolationsByCropTypeId(id: string | undefined): Promise<any> {
    if (!id) {
      return [];
    }

    return this.fetch(["CropTypes", id, "diseases"].join("/"), {
      method: "GET",
    });
  }

  //eslint-disable-next-line
  async getPestViolationsByCropTypeId(id: string | undefined): Promise<any> {
    if (!id) {
      return [];
    }

    return this.fetch(["CropTypes", id, "pests"].join("/"), { method: "GET" });
  }
}

export const observationsService = new ObservationsService();
