import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const alertStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: "#f77b71",
    },
    warning: {
      color: "#ffb64c",
    },
    info: {
      color: "#63b5f6",
    },
    success: {
      color: "#83c97f",
    },
    alert: {
      alignItems: "center",
      fontSize: "12px",
      padding: "0 10px",
    },
  })
);
