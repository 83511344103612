import { PaletteOptions } from "@material-ui/core/styles/createPalette";

import { THEME_PRIMARY_MAIN_COLOR } from "./mui-theme-constants";

export const palette: PaletteOptions = {
  primary: {
    light: "#2196f3", //"#709d4b",
    main: THEME_PRIMARY_MAIN_COLOR,
    dark: "#b2dc8f",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#ffffff",
    main: "#ffffff",
    dark: "#ffffff",
  },
  info: {
    light: "#b9d8f1",
    main: "#5eb8ff",
    dark: "#5a8eb7",
    contrastText: "#ffffff",
  },
  error: {
    light: "#f88e86",
    main: "#f44336",
    dark: "#a2271e",
    contrastText: "#ffffff",
  },
  warning: {
    light: "#ffc166",
    main: "#ff9800",
    dark: "#bb7103",
    contrastText: "#ffffff",
  },
  success: {
    light: "#5aa257",
    main: "#82d87e",
    dark: "#b4e7b1",
    contrastText: "#ffffff",
  },
  text: {
    primary: "#464545",
    secondary: "#676767",
    disabled: "#a3a3a3",
    hint: "#676767",
  },
  background: {
    default: "#f7f9fc",
  },
};
