export const PATHS = {
  MAIN_PAGE: "/",
  CATALOG_PAGE: "/catalogs",
  LOGIN_PAGE: "/login",
  TECHOPERATIONS_PAGE: "/tech-operations",
  OBSERVATIONS_PAGE: "/observations",
  MAP_PAGE: "/map",
  FIELDS_PAGE: "/fields",
  DASHBOARD: "/dashboard",
  EDIT_PAGE: "editing",
  EDIT_NEW_PAGE: "new",
};
