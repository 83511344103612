import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import {
  deleteFertilisersAction,
  fetchFertilisersAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";

const FertilisersTable = lazy(() => import("../fertilisers-table/fertilisers-table"));

const FertilisersTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const selectedIds = useSelector(getSelectedIds);
  const onDelete = useCallback(
    () => dispatch(deleteFertilisersAction(selectedIds)).then(() => dispatch(fetchFertilisersAction())),
    [selectedIds, dispatch]
  );
  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.FERTILISER}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <FertilisersTable />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default FertilisersTab;
