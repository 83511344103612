import { IFilter } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { normalizeFilter } from "../../../../shared/utils/normalize-filter";
import { IEmployeesDto } from "../dtos/employees";

class EmployeesService extends BaseService<IEmployeesDto, Partial<IFilter>> {
  private path = "Employees";

  async get(id: string): Promise<IEmployeesDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  // eslint-disable-next-line
  async add(data: IEmployeesDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async update(data: IEmployeesDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  async list(filter?: Partial<IFilter>): Promise<IEmployeesDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: filter ? { filter: normalizeFilter(filter) } : {},
    });
  }

  async listCount(filter: Partial<IFilter>): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }
}

export const employeesService = new EmployeesService();
