import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: "relative",
  },
  media: {
    height: 200,
  },
  actions: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));
