import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useMemo } from "react";
import { MapContainerProps } from "react-leaflet";
import { useSelector } from "react-redux";

import { getInfoDepartments, getInfoFarms } from "../../../../modules/info-data";
import { FarmLand } from "../../shared/models/farm-land";
import { FarmLandBriefMap } from "../farm-land-brief-map/farm-land-brief-map";

interface IProps extends MapContainerProps {
  model: FarmLand | null;
  isLoading?: boolean;
}

export const FarmLandBrief = (props: IProps): JSX.Element => {
  const { model, isLoading } = props;
  const farms = useSelector(getInfoFarms);
  const departments = useSelector(getInfoDepartments);

  const farmName = useMemo(() => {
    if (!model) {
      return "-";
    }
    const farm = farms.find((item) => item.id === model.farmId);
    return farm ? farm.name : "-";
  }, [farms, model]);

  const departmentName = useMemo(() => {
    if (!model) {
      return "-";
    }
    const department = departments.find((item) => item.id === model.departmentId);
    return department ? department.name : "-";
  }, [departments, model]);

  return (
    <Box width={200}>
      {isLoading || model === null ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton height={150} variant="rect" />
        </>
      ) : (
        <>
          <Typography variant={"h5"}>{model.name}</Typography>
          <Typography variant={"h6"}>{model.area} га</Typography>
          <Typography variant={"body2"}>
            Хозяйство: {farmName} ({departmentName})
          </Typography>
          <FarmLandBriefMap model={model} />
        </>
      )}
    </Box>
  );
};
