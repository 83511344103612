export const findModelByProperty = <T extends { id: unknown }>(
  collection: T[],
  fieldValue: T[keyof T],
  fieldName: keyof T = "id"
): T | null => {
  if (!collection?.length || !fieldValue) {
    return null;
  }
  return collection.find((item) => item[fieldName] === fieldValue) || null;
};
