import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { IObservationWithIncludesDto } from "../shared/dtos/observations-with-includes.dto";
import { IObservationsState } from "../shared/interfaces/observations-state";
import { ObservationWithInclude } from "../shared/models/observations-with-include.models";
import { observationsService } from "../shared/services/observations.service";

const initialState: IObservationsState = {
  isLoading: false,
  list: [],
  listCount: 0,
};

export const MODULE_NAME = "observations";
export const observationsSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialState,
  reducers: {
    setListAction(state, action): void {
      state.list = action.payload;
    },
    setListCountAction(state, action): void {
      state.listCount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchObservationsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchObservationsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchObservationsAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const observationsReducer = observationsSlice.reducer;
export const { setListAction, setListCountAction } = observationsSlice.actions;

// async actions
let abortController: AbortController | null = null;
export const fetchObservationsAction = createAsyncThunk<void, ILoopbackFilter>(
  `${MODULE_NAME}/fetchObservations`,
  async (filter, { dispatch }) => {
    // prevent multiple calls
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    let count: { count: number };
    let dtos: IObservationWithIncludesDto[];
    try {
      count = await observationsService.listCount(filter, { signal: abortController.signal });
      dtos = await observationsService.list(filter, { signal: abortController.signal });
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "AbortError") {
          return;
        }
      }
      throw e;
    }
    abortController = null;
    const models = dtos.map((dto) => {
      const model = new ObservationWithInclude();
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);
