import { Theme, makeStyles } from "@material-ui/core/styles";

export const useProgressStyles = makeStyles((theme: Theme) => ({
  background: {
    height: "100%",
    width: "97%",
    background: "rgb(128 128 128 / 24%)",
    position: "absolute",
    top: 0,
    left: "56px",
    zIndex: 2000,
    padding: "20%",
    display: "flex",
    justifyContent: "center",
  },
}));
