import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { FilterName } from "../shared/enums/filter-name";
import { IFilters, IFiltersState } from "../shared/interfaces/filters-state";
import { MODULE_NAME } from "./filters.slice";

// eslint-disable-next-line
export const getFiltersState = (rootState: any): IFiltersState => rootState[MODULE_NAME];

export const getInit = createDraftSafeSelector(getFiltersState, (state: IFiltersState) => state.init);

export const getFilter = createDraftSafeSelector(
    getFiltersState,
    (_, filterName: FilterName) => filterName,
    (state: IFiltersState, filterName) => state.filters[filterName]
);

export const getFilterComposition = createDraftSafeSelector(
    getFiltersState,
    (_, filterNames: FilterName[]) => filterNames,
    (state: IFiltersState, filterNames) => {
        return filterNames.reduce((acc, filterName) => {
            return {
                ...acc,
                [filterName]: state.filters[filterName],
            };
        }, {} as Partial<IFilters>);
    }
);

export const getFilterLoading = createDraftSafeSelector(
    getFiltersState,
    (_, filterName: FilterName) => filterName,
    (state: IFiltersState, filterName) => state.loading[filterName]
);

export const getFilterDisabling = createDraftSafeSelector(
    getFiltersState,
    (_, filterName: FilterName) => filterName,
    (state: IFiltersState, filterName) => state.disabling[filterName]
);
