export enum FilterName {
  Custom = "Custom",
  FarmId = "FarmId",
  FarmName = "FarmName",
  SeasonId = "SeasonId",
  Season = "Season",
  ArcgisFarmName = "ArcgisFarmName",
  StartDate = "StartDate",
  EndDate = "EndDate",
}
