import { makeStyles } from "@material-ui/core/styles";

export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  userInfoRoot: {
    display: "inline-block",
  },
  defaultFarm: {
    fontSize: "1.2em",
    color: "#44a6f5",
  },
  userIcon: {
    width: "52px",
    height: "52px",
  },
  menuItem: {
    fontSize: "0.95em",
    minWidth: "230px",
  },
  menuItemIcon: { margin: "0 6px 0 -6px" },
}));
