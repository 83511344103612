import { API_URL } from "../config/api";

export const checkAccessTokenAPI = async (): Promise<boolean> => {
  const { access_token, user_id } = localStorage;

  if (!access_token || !user_id) {
    return false;
  }
  const url = new URL(`${API_URL}/AppUsers/${user_id}/accessTokens/${access_token}?access_token=${access_token}`);

  try {
    const response = await fetch(url.toString(), {});
    return response.status === 200;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Токен не действителен.`);
    return false;
  }
};
