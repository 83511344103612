import React, { lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { setFilterDisablingAction } from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { useAppDispatch } from "../../../../store";
import { FarmLandDetails } from "../farm-land-details/farm-land-details";
import { FarmLandsArcgisidRedirect } from "../farm-lands-arcgisid-redirect/farm-lands-arcgisid-redirect";

const FarmLandMaster = lazy(() => import("../farm-land-master/farm-land-master"));

export const FarmLands = (): JSX.Element => {
  const dispatch = useAppDispatch();

  // disable global farm name filter on these pages
  useEffect(() => {
    dispatch(
      setFilterDisablingAction({
        filter: FilterName.ArcgisFarmName,
        value: true,
      })
    );
    dispatch(setFilterDisablingAction({ filter: FilterName.Season, value: true }));
    return () => {
      dispatch(
        setFilterDisablingAction({
          filter: FilterName.ArcgisFarmName,
          value: false,
        })
      );
      dispatch(setFilterDisablingAction({ filter: FilterName.Season, value: false }));
    };
    // eslint-disable-next-line
  }, [dispatch, setFilterDisablingAction]);

  return (
    <CommonLayout title={"Карточка поля"} contentPadding={0}>
      <Routes>
        <Route index={true} element={<FarmLandMaster />} />
        <Route path={"arcgis/:id"} element={<FarmLandsArcgisidRedirect />} />
        <Route path={":id/*"} element={<FarmLandDetails />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </CommonLayout>
  );
};
