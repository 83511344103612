import { TextField } from "@material-ui/core";
import React from "react";

import { InputLoginFormTypes, InputLoginProps } from "./login-page-types";
import "./login-page.scss";

export const InputEmail = (props: InputLoginProps): JSX.Element => {
  const { inputRef, values, setValues } = props;

  const handleChange = (prop: keyof InputLoginFormTypes) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <TextField
      required
      inputRef={inputRef}
      id="email"
      label="Логин (e-mail)"
      name="email"
      type="email"
      value={values.email}
      onChange={handleChange("email")}
      className="input-login"
    />
  );
};
