import { store } from "../../../store";
import { Util } from "../../utils";

export const USE_ROLE_KEY = "use_role";
const USE_ROLE_NAMESPACE = "@useRole";

export const SET_USER_ROLE = `${USE_ROLE_NAMESPACE}/SET_USER_ROLE`;
//eslint-disable-next-line
export const setUserRole = (role: string | undefined) => ({
  type: SET_USER_ROLE,
  payload: role,
});

//eslint-disable-next-line
export const fetchUserRole = async () => {
  const { [USE_ROLE_KEY]: state } = store.getState();

  if (!state?.roleName) {
    const appUser = await Util.getFeaturesAPI("AppUsers", localStorage["user_id"], undefined, undefined, undefined, [
      { roles: ["id", "name", "roleName"] },
    ]);

    if (appUser && appUser.roles.length) {
      const roleName = appUser.roles[0].name;
      store.dispatch(setUserRole(roleName));
    }
  }

  return state?.roleName;
};
