import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ICropTypesState } from "../shared";
import { MODULE_NAME } from "./crop-types.slice";

// eslint-disable-next-line
export const getCropTypesState = (rootState: any): ICropTypesState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getCropTypesState, (state: ICropTypesState) => state.isLoading);
export const getList = createDraftSafeSelector(getCropTypesState, (state: ICropTypesState) => state.list);
