import { v4 as uuidv4 } from "uuid";

import { IObservationViolation } from "../../../../shared/interface/observation-violation";
import { IObservationDto } from "../dtos/observations.dto";
import { IObservationFormData } from "../interfaces/observation-form-data";

export class Observation {
  id: string;
  farmId: string;
  farmLandId: string;
  phenoPhaseId: string;
  cropTypeId: string;
  seasonId: string;
  inputDate: number; // unixtime
  updatedAt: number; // unixtime
  reportNumber: number;
  comment: string;
  violations: IObservationViolation[] | null;
  isReportComplete = false;
  appUserId: string;

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  get asDto(): IObservationDto {
    return {
      id: this.id,
      farmId: this.farmId,
      farmLandId: this.farmLandId,
      phenoPhaseId: this.phenoPhaseId,
      cropTypeId: this.cropTypeId,
      inputDate: this.inputDate,
      updatedAt: this.updatedAt,
      seasonId: this.seasonId,
      reportNumber: this.reportNumber,
      comment: this.comment,
      violations: this.violations,
      isReportComplete: this.isReportComplete,
      appUserId: this.appUserId,
    };
  }

  updateFromDto(dto: IObservationDto): void {
    this.id = dto.id;
    this.farmId = dto.farmId;
    this.farmLandId = dto.farmLandId;
    this.phenoPhaseId = dto.phenoPhaseId;
    this.cropTypeId = dto.cropTypeId;
    this.inputDate = dto.inputDate;
    this.updatedAt = dto.updatedAt;
    this.seasonId = dto.seasonId;
    this.reportNumber = dto.reportNumber;
    this.comment = dto.comment;
    this.violations = dto.violations;
    this.appUserId = dto.appUserId;
  }

  get asFormData(): IObservationFormData {
    return {
      id: this.id,
      farmId: this.farmId || "",
      farmLandId: this.farmLandId || "",
      phenoPhaseId: this.phenoPhaseId || "",
      cropTypeId: this.cropTypeId || "",
      inputDate: Number(this.inputDate) || new Date().getTime(),
      seasonId: this.seasonId || "",
      comment: this.comment || "",
      violations: this.violations?.length ? this.violations : [],
    };
  }

  updateFromFormData(data: IObservationFormData): void {
    this.farmId = data.farmId;
    this.farmLandId = data.farmLandId;
    this.phenoPhaseId = data.phenoPhaseId;
    this.cropTypeId = data.cropTypeId;
    this.inputDate = data.inputDate || new Date().getTime();
    this.seasonId = data.seasonId;
    this.comment = data.comment;
    this.violations = data.violations?.length ? data.violations : null;
  }

  completeReport(): void {
    this.isReportComplete = true;
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setFarmLandId(farmLandId: string | null): Observation {
    if (farmLandId) {
      this.farmLandId = farmLandId;
    }
    return this;
  }
}
