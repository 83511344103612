import { v4 as uuidv4 } from "uuid";

import { ProductionType } from "../../../../shared/enums";
import { formatDateHuman } from "../../../../shared/utils";
import { ITechOperationValue } from "../dtos/tech-operation-param.dto";
import { ITechOperationDto } from "../dtos/tech-operation.dto";
import { ITechOperationFormData } from "../interfaces/tech-operation-form-data";
import { TechOperationAsset } from "./tech-operation-asset";
import { TechOperationValue } from "./tech-operation-value";

export class TechOperation {
  readonly id: string;
  farmId: string;
  farmLandId: string;
  farmLandName: string;
  cropTypeId: string;
  startedAt: number; // unixtime
  finishedAt: number; // unixtime
  techOperationGroupId: string;
  techOperationSubGroupId: string;
  fieldSize: number;
  operationNumber?: number;
  productionType: ProductionType;
  techAssetId: string;
  trailerAssetId: string | null;
  employeeId: string;
  seasonId?: string;
  massCleanedProduct?: number;
  productivitySet?: number;
  appUserId: string;
  TechOperationValues: ITechOperationValue[];
  Asset: TechOperationAsset[];

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  get workPeriod(): string {
    return `${formatDateHuman(new Date(this.startedAt))} – ${
      this.finishedAt && formatDateHuman(new Date(this.finishedAt))
    }`;
  }

  get asDto(): ITechOperationDto {
    return {
      id: this.id,
      farmId: this.farmId,
      seasonId: this.seasonId,
      farmLandId: this.farmLandId,
      farmLandName: this.farmLandName,
      cropTypeId: this.cropTypeId,

      startedAt: this.startedAt,
      finishedAt: this.finishedAt,
      techOperationGroupId: this.techOperationGroupId,
      techOperationSubGroupId: this.techOperationSubGroupId,
      fieldSize: this.fieldSize,
      operationNumber: this.operationNumber,

      productionType: this.productionType,
      techAssetId: this.techAssetId,
      trailerAssetId: this.trailerAssetId,
      employeeId: this.employeeId,

      massCleanedProduct: this.massCleanedProduct,
      productivitySet: this.productivitySet,

      appUserId: this.appUserId,

      TechOperationValues: this.TechOperationValues?.map((item) => {
        const result = new TechOperationValue(item.id);
        result.updateFromFormData(item);
        result.setFarmId(this.farmId);
        result.setTechOperationId(this.id);
        return result;
      }),
      Asset: this.Asset?.map((asset) => asset.asDto),
    };
  }

  updateFromDto(dto: ITechOperationDto): void {
    this.farmId = dto.farmId;
    this.seasonId = dto.seasonId;
    this.farmLandId = dto.farmLandId;
    this.farmLandName = dto.farmLandName;
    this.cropTypeId = dto.cropTypeId;

    this.startedAt = dto.startedAt;
    this.finishedAt = dto.finishedAt;
    this.techOperationGroupId = dto.techOperationGroupId;
    this.techOperationSubGroupId = dto.techOperationSubGroupId;
    this.fieldSize = dto.fieldSize;
    this.operationNumber = dto.operationNumber;

    this.productionType = dto.productionType;
    this.techAssetId = dto.techAssetId;
    this.trailerAssetId = dto.trailerAssetId;
    this.employeeId = dto.employeeId;

    this.massCleanedProduct = dto.massCleanedProduct;
    this.productivitySet = dto.productivitySet;

    this.appUserId = dto.appUserId;

    this.TechOperationValues = dto.TechOperationValues || [];
    this.Asset =
      dto.Asset?.map((asset) => {
        const result = new TechOperationAsset(asset.id);
        result.updateFromDto(asset);
        return result;
      }) || [];
  }

  get asFormData(): ITechOperationFormData {
    return {
      id: this.id,
      farmId: this.farmId || "",
      seasonId: this.seasonId || "",
      farmLandId: this.farmLandId || "",
      cropTypeId: this.cropTypeId || "",
      // farmLandName: this.farmLandName,

      startedAt: Number(this.startedAt) || new Date().getTime(),
      finishedAt: Number(this.finishedAt) || new Date().getTime(),
      techOperationGroupId: this.techOperationGroupId || "",
      techOperationSubGroupId: this.techOperationSubGroupId || "",
      fieldSize: this.fieldSize || 0,
      operationNumber: this.operationNumber || "",

      productionType: this.productionType || ProductionType.default,
      techAssetId: this.techAssetId || "",
      trailerAssetId: this.trailerAssetId === null ? "-1" : this.trailerAssetId || "",
      employeeId: this.employeeId || "",

      massCleanedProduct: this.massCleanedProduct || 0,
      productivitySet: this.productivitySet,

      params:
        this.TechOperationValues?.reduce((acc, item) => {
          acc[`operationParamId-${item.operationParamId}`] = item;
          return acc;
        }, {}) || {},
      assets: this.Asset?.map((asset) => asset.asFormData) || [],
    };
  }

  updateFromFormData(data: ITechOperationFormData): void {
    this.farmId = data.farmId;
    this.seasonId = data.seasonId;
    this.farmLandId = data.farmLandId;
    this.cropTypeId = data.cropTypeId;
    // this.farmLandName = data.farmLandName;

    this.startedAt = data.startedAt;
    this.finishedAt = data.finishedAt;
    this.techOperationGroupId = data.techOperationGroupId;
    this.techOperationSubGroupId = data.techOperationSubGroupId;
    this.fieldSize = data.fieldSize;
    this.operationNumber = Number(data.operationNumber);

    this.productionType = data.productionType;
    this.techAssetId = data.techAssetId;
    this.trailerAssetId = data.trailerAssetId === "-1" ? null : data.trailerAssetId;
    this.employeeId = data.employeeId;

    this.massCleanedProduct = data.massCleanedProduct;
    this.productivitySet = data.productivitySet;

    this.TechOperationValues = Object.values(data.params);
    this.Asset =
      data.assets?.map((asset) => {
        const result = new TechOperationAsset(asset._id);
        result.updateFromFormData(asset);
        return result;
      }) || null;
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setFarmLandId(farmLandId: string | null): TechOperation {
    if (farmLandId) {
      this.farmLandId = farmLandId;
    }
    return this;
  }

  setAssets(assets: TechOperationAsset[]): void {
    this.Asset = assets;
  }
}
