import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  ImportContacts as ImportContactsIcon,
  Map as MapIcon,
  Menu as MenuIcon,
  NaturePeople as NaturePeopleIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { PATHS } from "../../constant";
import { getRole } from "../../utils/hooks/use-role/use-role";
import { UserPanel } from "../user-panel/user-panel";
import { sidebarStyles } from "./sidebar-style";

interface ISidebarPanelProps {
  title: string;
}

interface ILinkProps {
  path: string;
  menuText: string;
  icon: JSX.Element;
  isDisplayed: boolean;
}

export const SidebarPanel = (props: ISidebarPanelProps): JSX.Element => {
  const { title } = props;
  const [open, setOpen] = React.useState(false);

  const [role, setRole] = React.useState<string | null>(null);
  useEffect(() => {
    getRole((roleName) => setRole(roleName));
  }, [role]);

  const classes = sidebarStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const listLinks: ILinkProps[] = [
    // {
    //     path: PATHS.DASHBOARD,
    //     menuText: "Дашборд",
    //     isDisplayed: true,
    //     icon: <DashboardIcon key="DashboardIcon" color="secondary"/>,
    // },
    {
      path: PATHS.MAP_PAGE,
      menuText: "Карта",
      isDisplayed: role === "user" || role === "admin" || role === "manager" || role === "external",
      icon: <MapIcon key="MapIcon" color="secondary" />,
    },
    {
      path: PATHS.TECHOPERATIONS_PAGE,
      menuText: "Техоперации",
      isDisplayed: role === "user" || role === "admin" || role === "manager",
      icon: <ImportContactsIcon key="TechOperationsIcon" color="secondary" />,
    },
    {
      path: PATHS.OBSERVATIONS_PAGE,
      menuText: "Осмотры",
      isDisplayed: role === "user" || role === "admin" || role === "manager" || role === "external",
      icon: <NaturePeopleIcon key="ObservationsIcon" color="secondary" />,
    },
    {
      path: PATHS.CATALOG_PAGE,
      menuText: "Справочники",
      isDisplayed: role === "user" || role === "admin" || role === "manager",
      icon: <SettingsIcon key="SettingsIcon" color="secondary" />,
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h4" noWrap>
            {title}
          </Typography>
          <UserPanel />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <CloseIcon color="secondary" /> : <MenuIcon color="secondary" />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {listLinks
            .filter((link) => link.isDisplayed)
            .map((item, index) => (
              // TODO: change on activeStyle or activeClass when style library will be @mui instead of @material-ui
              <NavLink key={item.path} to={item.path}>
                {({ isActive }) => (
                  <ListItem button={true} style={{ backgroundColor: isActive ? "orange" : "" }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.menuText} color="secondary" className={classes.listItemText} />
                  </ListItem>
                )}
              </NavLink>
            ))}
        </List>
      </Drawer>
    </div>
  );
};
