import { Box, Grid, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getChosenPhotosList, getOldPhotosList } from "../../store/observation-editing.selector";
import {
  deleteChosenPhotoAction,
  deleteExistingPhotoAction,
  setChosenPhotosAction,
} from "../../store/observation-editing.slice";
import { PhotoUploadPreview } from "../observation-photos-upload-preview/observation-photos-upload-preview";

export const ObservationPhotosUpload = (): JSX.Element => {
  const dispatch = useDispatch();
  const oldPhotos = useSelector(getOldPhotosList);
  const chosenPhotos = useSelector(getChosenPhotosList);

  const onPhotoChoose = useCallback(
    (data) => {
      dispatch(setChosenPhotosAction(data));
    },
    [dispatch]
  );

  const onOldPhotoDelete = useCallback(
    (id) => {
      dispatch(deleteExistingPhotoAction(id));
    },
    [dispatch]
  );

  const onChosenPhotoDelete = useCallback(
    (idx) => {
      dispatch(deleteChosenPhotoAction(idx));
    },
    [dispatch]
  );

  return (
    <>
      <DropzoneArea
        onChange={onPhotoChoose}
        filesLimit={9999}
        acceptedFiles={["image/jpeg"]}
        dropzoneText={"Перетащите фотографии отчета сюда, либо нажмите для выбора файлов"}
        showAlerts={false}
        showPreviews={false}
        showPreviewsInDropzone={false}
        previewGridProps={{
          container: { spacing: 4 },
          item: { xs: 12, sm: 6, md: 4, lg: 2 },
        }}
      />

      {Boolean(oldPhotos.length || chosenPhotos.length) && (
        <>
          <Box py={2}>
            <Typography variant={"h5"}>Предпросмотр</Typography>
          </Box>

          <Grid container={true} spacing={4}>
            {oldPhotos.map((photo) => (
              <PhotoUploadPreview key={photo.id} src={photo.src} onDelete={() => onOldPhotoDelete(photo.id)} />
            ))}

            {chosenPhotos.map((photo, idx) => (
              <PhotoUploadPreview
                key={idx}
                src={window.URL.createObjectURL(photo)}
                onDelete={() => onChosenPhotoDelete(idx)}
              />
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
