import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ChartPeriod } from "../shared/enums/chart-period";
import { IFarmLandDataSet, IFarmLandsWeatherState } from "../shared/interfaces/farm-lands-weather-state";
import { fieldClimateService } from "../shared/services/field-climate.service";
import { subtractDaysFromDate } from "../shared/utils/subtract-days-from-date";
import { getFarmLandsWeatherState } from "./farm-lands-weather.selector";

export const MODULE_NAME = "farmLandsWeather";
export const farmLandsWeatherSlice = createSlice({
  name: MODULE_NAME,
  initialState: {
    isLoading: false,
    filter: {
      stationId: null,
      period: ChartPeriod.Hourly,
      from: subtractDaysFromDate(1, new Date()),
      to: new Date(),
    },
    dataSets: {
      dates: [],
      data: [],
    },
    charts: [],
  } as IFarmLandsWeatherState,
  reducers: {
    setLoadingAction(state, action): void {
      state.isLoading = action.payload as boolean;
    },
    setPeriodAction(state, action): void {
      state.filter.period = action.payload as ChartPeriod;
    },
    setStationIdAction(state, action): void {
      state.filter.stationId = action.payload as string | null;
    },
    setFromAction(state, action): void {
      state.filter.from = action.payload as Date;
    },
    setToAction(state, action): void {
      state.filter.to = action.payload as Date;
    },
    setChartsAction(state, action): void {
      state.charts = action.payload;
    },
    setDataSetsAction(state, action): void {
      state.dataSets = action.payload as {
        dates: string[];
        data: IFarmLandDataSet[];
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChartsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchChartsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchChartsAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const farmLandsWeatherReducer = farmLandsWeatherSlice.reducer;
export const {
  setStationIdAction,
  setDataSetsAction,
  setChartsAction,
  setToAction,
  setFromAction,
  setPeriodAction,
  setLoadingAction,
} = farmLandsWeatherSlice.actions;

// async actions
export const fetchChartsAction = createAsyncThunk(
  `${MODULE_NAME}/fetchChartsAction`,
  async (params, { getState, dispatch }) => {
    const state = getFarmLandsWeatherState(getState());

    // clear charts
    if (state.filter.stationId === null) {
      return dispatch(setChartsAction([]));
    }

    const chartDtos = await fieldClimateService.getCharts(state.filter);
    return dispatch(setChartsAction(chartDtos));
  }
);
