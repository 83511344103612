import { TextField, TextFieldProps } from "@material-ui/core";
import lodashGet from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { Controller, ControllerRenderProps, RegisterOptions, useFormContext } from "react-hook-form";

interface IProps {
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  renderOnChange?: (value: string, onChange: ControllerRenderProps["onChange"]) => void;
  TextFieldProps?: Partial<TextFieldProps>;
}

export const RHFTextField = (props: IProps): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(() => {
    return lodashGet(rhfMethods.formState.errors, props.name);
  }, [props, rhfMethods.formState.errors]);

  const renderTextField = useCallback(
    ({ onChange, value }) => (
      <>
        <TextField
          value={value || ""}
          onChange={(evt) =>
            props.renderOnChange ? props.renderOnChange(evt.target.value, onChange) : onChange(evt.target.value)
          }
          fullWidth={true}
          error={Boolean(error)}
          {...props.TextFieldProps}
        />
      </>
    ),
    [error, props]
  );

  return (
    <Controller
      name={props.name}
      control={rhfMethods.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={renderTextField}
    />
  );
};
