import { Drawer } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getFilterComposition } from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { useAppDispatch } from "../../../../store";
import { fetchCropAggregationAction } from "../../store/map-controls.slice";
import { getIsDrawerOpen } from "../../store/map-page.selector";
import { CropTreeView } from "../crop-tree-view/crop-tree-view";
import { FilterCTVName } from "../filter-ctv-name/filter-ctv-name";

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    position: "relative",
    padding: (isDrawerOpen) => (isDrawerOpen ? theme.spacing(1) : 0),
    width: (isDrawerOpen) => (!isDrawerOpen ? 0 : ""),
  },
}));

export const MapDrawerPanel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { Season, FarmId } = useSelector((state) =>
    getFilterComposition(state, [FilterName.FarmId, FilterName.Season])
  );
  const isDrawerOpen = useSelector(getIsDrawerOpen);
  const classes = useStyle(isDrawerOpen);

  useEffect(() => {
    if (!FarmId || !Season) {
      return;
    }
    dispatch(
      fetchCropAggregationAction({
        farmId: FarmId,
        seasonName: Season.toString(),
        filter: {},
      })
    );
    // eslint-disable-next-line
  }, [Season, FarmId]);

  return (
    <Drawer variant={"persistent"} anchor={"left"} open={isDrawerOpen} classes={classes}>
      <FilterCTVName />
      <CropTreeView />
    </Drawer>
  );
};
