import { Feature, GeometryObject } from "geojson";
import { GeoJSON, Polyline } from "leaflet";
import React, { useCallback, useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CircularProgressComponent } from "../../../../components/progress";
import { getFilterComposition } from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { GeoJsonWithUpdates } from "../../../../shared/components/geo-json-with-updates/geo-json-with-updates";
import { useAppDispatch } from "../../../../store";
import { TArcgisMapCropPropsDto } from "../../shared/dtos/arcgis-map-crop-props.dto";
import { useCropStyles } from "../../shared/utils/use-crop-styles";
import { getSearchedArcgisIds } from "../../store/map-controls.selector";
import {
  getBounds,
  getGeoJson,
  getHighlightedFarmLandId,
  getIsLoading,
  getSelectedCropNames,
} from "../../store/map-page.selector";
import { fetchGeoJsonAction, setBoundsAction, setHighlightedFarmLandId } from "../../store/map-page.slice";
import { useStyles } from "./farm-lands-layer.styles";

export const LayerPreloader = (): JSX.Element => {
  const classes = useStyles();
  const isLoading = useSelector(getIsLoading);

  return (
    <div className={classes.circularProgressComponent}>
      <CircularProgressComponent show={isLoading} />
    </div>
  );
};

type FarmLandsLayerProps = {
  setFeature: React.Dispatch<React.SetStateAction<TArcgisMapCropPropsDto | null>>;
};

export const FarmLandsLayer = (props: FarmLandsLayerProps): JSX.Element => {
  const { setFeature } = props;
  const map = useMap();
  const navigate = useNavigate();
  const { style } = useCropStyles();
  const dispatch = useAppDispatch();
  const geoJson: GeoJSON<TArcgisMapCropPropsDto> = useSelector(getGeoJson);
  const bounds = useSelector(getBounds);
  const isLoading = useSelector(getIsLoading);
  const selectedCropNames = useSelector(getSelectedCropNames);
  const searchedArcgisIds = useSelector(getSearchedArcgisIds);
  const { ArcgisFarmName, Season } = useSelector((state) =>
    getFilterComposition(state, [FilterName.ArcgisFarmName, FilterName.Season])
  );
  const highlightedFarmLandId = useSelector(getHighlightedFarmLandId);

  const geoJsonObject = useMemo(() => geoJson.toGeoJSON(), [geoJson]);

  const onEachFeature = useCallback(
    (feature: Feature<GeometryObject, TArcgisMapCropPropsDto>, layer) => {
      const { properties } = feature;

      layer.on({
        mouseover: () => {
          setFeature(properties);
        },
        mouseout: () => {
          setFeature(null);
        },
        click: () => {
          navigate(`/fields/arcgis/${properties.GDB_ARCHIVE_OID}`);
        },
      });
    },
    [setFeature, navigate]
  );

  const filter = useCallback(
    (feature: Feature<GeometryObject, TArcgisMapCropPropsDto>) => {
      // filter by crops
      if (selectedCropNames.length && !selectedCropNames.includes(feature.properties.crop)) {
        return false;
      }

      // filter by arcgisId
      if (searchedArcgisIds.length && !searchedArcgisIds.includes(feature.properties.GDB_ARCHIVE_OID)) {
        return false;
      }

      return true;
    },
    [selectedCropNames, searchedArcgisIds]
  );

  useEffect(() => {
    if (!highlightedFarmLandId) {
      return;
    }
    const layers = geoJson.getLayers() as Polyline<GeometryObject, TArcgisMapCropPropsDto>[];
    const layer = layers.find((item) => item.feature?.properties.GDB_ARCHIVE_OID.toString() === highlightedFarmLandId);

    if (layer) {
      dispatch(setBoundsAction(layer.getBounds()));
      dispatch(setHighlightedFarmLandId(null));
    }
  }, [dispatch, map, geoJson, highlightedFarmLandId]);

  useEffect(() => {
    if (!ArcgisFarmName || !Season) {
      return;
    }
    dispatch(fetchGeoJsonAction({ farm: ArcgisFarmName, season: Season }));
  }, [ArcgisFarmName, dispatch, Season]);

  useEffect(() => {
    map.fitBounds(bounds);
  }, [dispatch, map, bounds]);

  if (!geoJson || !geoJson.getLayers().length || isLoading) {
    return <LayerPreloader />;
  }

  return (
    <GeoJsonWithUpdates
      data={geoJsonObject}
      onEachFeature={onEachFeature}
      filter={filter}
      style={style}
    ></GeoJsonWithUpdates>
  );
};
