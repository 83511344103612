import { IObservationViolation } from "../../../../shared/interface/observation-violation";
import { DateTimeFormatter } from "../../../../utils/formatters";
import { IObservationWithIncludesDto } from "../dtos/observations-with-includes.dto";

export class ObservationWithInclude {
  id: string;
  farmId: string;
  farmLandId: string;
  phenoPhaseId: string;
  cropTypeId: string;
  seasonId: string;
  inputDate: number; // unixtime
  updatedAt: number; // unixtime
  reportNumber: number;
  comment: string;
  violations: IObservationViolation[] | null;

  appUser: { fullName?: string };
  crop: { name?: string };
  farm: { name?: string };
  farmLand: { name?: string };
  phenoPhase: { name?: string };

  isReportComplete = false;
  appUserId: string;

  get createdDate(): string {
    return DateTimeFormatter(this.inputDate, true);
  }

  get editDate(): string {
    return DateTimeFormatter(this.updatedAt, true);
  }

  get asDto(): IObservationWithIncludesDto {
    return {
      id: this.id,
      farmId: this.farmId,
      farmLandId: this.farmLandId,
      phenoPhaseId: this.phenoPhaseId,
      cropTypeId: this.cropTypeId,
      inputDate: this.inputDate,
      updatedAt: this.updatedAt,
      seasonId: this.seasonId,
      reportNumber: this.reportNumber,
      comment: this.comment,
      violations: this.violations,
      appUser: { fullName: this.appUser?.fullName },
      crop: { name: this.crop?.name },
      farm: { name: this.farm?.name },
      farmLand: { name: this.farmLand?.name },
      phenoPhase: { name: this.phenoPhase?.name },
      isReportComplete: this.isReportComplete,
      appUserId: this.appUserId,
    };
  }

  updateFromDto(dto: IObservationWithIncludesDto): void {
    this.id = dto.id;
    this.farmId = dto.farmId;
    this.farmLandId = dto.farmLandId;
    this.phenoPhaseId = dto.phenoPhaseId;
    this.cropTypeId = dto.cropTypeId;
    this.inputDate = dto.inputDate;
    this.updatedAt = dto.updatedAt;
    this.seasonId = dto.seasonId;
    this.reportNumber = dto.reportNumber;
    this.comment = dto.comment;
    this.violations = dto.violations;
    this.appUser = { fullName: dto.appUser?.fullName };
    this.crop = { name: dto.crop?.name };
    this.farm = { name: dto.farm?.name };
    this.farmLand = { name: dto.farmLand?.name };
    this.phenoPhase = { name: dto.phenoPhase?.name };
  }
}
