import { IDiseaseDto } from "../dtos/diseases";

export class Disease {
  readonly id: string;
  name: string;
  biologicalGroup: string;
  latinName: string;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IDiseaseDto {
    return {
      id: this.id,
      name: this.name,
      biologicalGroup: this.biologicalGroup,
      latinName: this.latinName,
    };
  }

  updateFromDto(dto: IDiseaseDto): void {
    this.name = dto.name;
    this.biologicalGroup = dto.biologicalGroup;
    this.latinName = dto.latinName;
  }
}
