import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IAppUsersState } from "../shared";
import { MODULE_NAME } from "./app-users.slice";

// eslint-disable-next-line
export const getAppUsersState = (rootState: any): IAppUsersState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getAppUsersState, (state: IAppUsersState) => state.isLoading);
export const getList = createDraftSafeSelector(getAppUsersState, (state: IAppUsersState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getAppUsersState, (state: IAppUsersState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getAppUsersState, (state: IAppUsersState) => state.searchText);
