import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IDiseasesState } from "../shared";
import { MODULE_NAME } from "./diseases.slice";

// eslint-disable-next-line
export const getDiseasesState = (rootState: any): IDiseasesState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.isLoading);
export const getList = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.searchText);
