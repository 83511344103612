import { Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { TLabel } from "../../../../shared/interface";
import { useAppDispatch } from "../../../../store";
import { getFarmLandNameSearchOptions } from "../../store/map-controls.selector";
import { setSearchFarmLandNameAction } from "../../store/map-controls.slice";
import { setHighlightedFarmLandId } from "../../store/map-page.slice";

export const FilterCTVName = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const aggregationOptions = useSelector(getFarmLandNameSearchOptions);

  const handleOnChange = useCallback(
    (_, option: TLabel | null | string) => {
      if (!option) {
        return;
      }

      if (typeof option === "string") {
        return;
      }

      dispatch(setHighlightedFarmLandId(option.id));
    },
    [dispatch]
  );

  const handleOnInputChange = useCallback(
    (e, value) => {
      if (!value) {
        dispatch(setSearchFarmLandNameAction(""));
        return;
      }

      dispatch(setSearchFarmLandNameAction(value));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setSearchFarmLandNameAction(""));
  }, [dispatch]);

  return (
    <Paper>
      <Autocomplete
        id={"filter-ctv-name"}
        freeSolo={true}
        options={aggregationOptions}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.title)}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={(x) => x}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        fullWidth={true}
        noOptionsText={"Поля не найдены"}
        loadingText={"Грузим..."}
        renderInput={(params) => (
          <TextField {...params} InputLabelProps={{ shrink: true }} label="Название поля" variant="outlined" />
        )}
      />
    </Paper>
  );
};
