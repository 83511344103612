import { FeatureCollection, GeoJsonObject, Geometry } from "geojson";
import { GeoJSON } from "leaflet";

import { IGeoFeatureLayerDto } from "../../../../shared/dtos/geo-feature-layer.dto";
import { ArcGisFormat } from "../../../../shared/interface/arcgis-filter";
import { BaseArcgisService } from "../../../../shared/services/base-arcgis.service";
import { TArcgisBriefCropPropsDto } from "../dtos/arcgis-brief-crop-props.dto";
import { TArcgisMapCropPropsDto } from "../dtos/arcgis-map-crop-props.dto";
import { TArcgisSearchCropPropsDto } from "../dtos/arcgis-search-crop-props.dto";
import { ArcgisCropField } from "../enums/arcgis-crop-field";
import { arcgisLayerToProps } from "../utils/arcgis-layer-to-props";

export type TBriefCropGeoJson = GeoJSON<TArcgisBriefCropPropsDto>; // TODO: change on Feature, geoJson construct inside reducers

class ArcgisCropsService extends BaseArcgisService {
  protected path = `ekocrop/FeatureServer/0`;

  getMapData({
    farm,
    season,
  }: {
    farm: string;
    season: number;
  }): Promise<FeatureCollection<Geometry, TArcgisMapCropPropsDto>> {
    return this.list<FeatureCollection<Geometry, TArcgisMapCropPropsDto>>({
      where: `${ArcgisCropField.farm}='${farm}' AND ${ArcgisCropField.season}=${season}`,
      returnGeometry: true,
      outFields: [
        ArcgisCropField.GDB_ARCHIVE_OID,
        ArcgisCropField.name,
        ArcgisCropField.farm,
        ArcgisCropField.area_2d,
        ArcgisCropField.season,
        ArcgisCropField.crop,
      ].join(","),
      f: ArcGisFormat.Geojson,
    });
  }

  getBriefData(id: number): Promise<TBriefCropGeoJson> {
    return this.list<GeoJsonObject>({
      where: [ArcgisCropField.GDB_ARCHIVE_OID, id].join("="),
      returnGeometry: true,
      returnCentroid: true,
      outFields: [ArcgisCropField.GDB_ARCHIVE_OID, ArcgisCropField.season, ArcgisCropField.crop].join(","),
      f: ArcGisFormat.Geojson,
    }).then((data) => new GeoJSON(data));
  }

  getSearchProps({
    nameLike,
    season,
    farm,
  }: {
    nameLike: string;
    season: number;
    farm: string;
  }): Promise<TArcgisSearchCropPropsDto[]> {
    return this.list<IGeoFeatureLayerDto<TArcgisSearchCropPropsDto>>({
      where: `${ArcgisCropField.name} LIKE '%${nameLike}%' AND ${ArcgisCropField.season}=${season} AND ${ArcgisCropField.farm}='${farm}'`,
      outFields: [
        ArcgisCropField.GDB_ARCHIVE_OID,
        ArcgisCropField.name,
        ArcgisCropField.farm,
        ArcgisCropField.season,
      ].join(","),
      f: ArcGisFormat.Json,
    }).then((layer) => arcgisLayerToProps<TArcgisSearchCropPropsDto>(layer));
  }
}

export const arcgisCropsService = new ArcgisCropsService();
