import { Grid, Paper } from "@material-ui/core";
import React from "react";

import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";

export const Dashboard = (): JSX.Element => {
  return (
    <CommonLayout title={"Панель управления"}>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={12} md={6}>
          <Paper style={{ height: "100%" }}>Dashboard</Paper>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6}></Grid>
      </Grid>
    </CommonLayout>
  );
};
