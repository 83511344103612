import { IFarmsDto } from "../dtos/farms";

export class Farm {
  id: string;
  name: string;

  get asDto(): IFarmsDto {
    return {
      id: this.id,
      name: this.name,
    };
  }

  updateFromDto(dto: IFarmsDto): void {
    this.id = dto.id;
    this.name = dto.name;
  }
}
