import { IconButton } from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../store";
import { getIsDrawerOpen } from "../../store/map-page.selector";
import { toggleDrawerOpenAction } from "../../store/map-page.slice";
import { useStyles } from "./map-filter-control.styles";

export const MapFilterControl = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isDrawerOpen = useSelector(getIsDrawerOpen);

  const handleToggleDrawer = useCallback(() => {
    dispatch(toggleDrawerOpenAction());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className={[classes.corner, classes.topLeft].join(" ")}>
        <IconButton size={"small"} style={{ opacity: isDrawerOpen ? 0.3 : 1 }} onClick={handleToggleDrawer}>
          <FilterListIcon />
        </IconButton>
      </div>
    </div>
  );
};
