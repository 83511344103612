import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ITechAssetsState } from "../shared";
import { MODULE_NAME } from "./techassets.slice";

// eslint-disable-next-line
export const getTechAssetsState = (rootState: any): ITechAssetsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getTechAssetsState, (state: ITechAssetsState) => state.isLoading);
export const getList = createDraftSafeSelector(getTechAssetsState, (state: ITechAssetsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(getTechAssetsState, (state: ITechAssetsState) => state.searchText);
