import { InfoDataState } from "./info-data-types";

export const INFO_DATA_KEY = "info_data";

export const INFO_DATA_INITIAL_STATE: InfoDataState = {
  cropTypes: [],
  departments: [],
  employees: [],
  farms: [],
  positions: [],
  seasons: [],
  workPlace: [],
  techTypes: [],
  techOperationGroup: [],
  techOperationSubGroup: [],
  techAssets: [],
  techAssetCategory: [],
  roles: [],
  phenoPhases: [],
  techOperationsScalars: [],
};
