import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFertilisersState } from "../shared";
import { MODULE_NAME } from "./fertilisers.slice";

// eslint-disable-next-line
export const getFertilisersState = (rootState: any): IFertilisersState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getFertilisersState, (state: IFertilisersState) => state.isLoading);
export const getList = createDraftSafeSelector(getFertilisersState, (state: IFertilisersState) => state.list);
export const getSelectedIds = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.searchText
);
