import { Grid, ListItem } from "@material-ui/core";
import Formula from "@sideway/formula";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { getTechOperationsScalars } from "../../../../modules/info-data/info-data-selectors";
import { ITechOperationScalar } from "../../../../modules/info-data/shared/interfaces/tech-operation-scalar";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { useAppDispatch } from "../../../../store";
import {
  ITechOperationArrayParamDetailsDto,
  ITechOperationArrayParamDto,
  ITechOperationParamWithArrayParamsDto,
} from "../../shared/dtos/tech-operation-param.dto";
import { fetchTechOperationSubGroupParamsBySubGroupId } from "../../store/tech-operations.slice";

interface IProps {
  isEditingTechOperation: boolean;
}

export const TechOperationEditingSubgroupParams = (props: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const rhfMethods = useFormContext();
  const watchFields = rhfMethods.watch();

  const techOperationsScalars = useSelector(getTechOperationsScalars);
  const [availableScalars, setAvailableScalars] = useState<{
    [key: ITechOperationParamWithArrayParamsDto["id"]]: ITechOperationScalar;
  }>({});
  const [scalarsFormulations, setScalarsFormulations] = useState<{ [key: string]: Formula.Parser<number> }>({});
  const [scalarsArguments, setScalarsArguments] = useState<Record<string, number | string>>({});

  const [subGroupParams, setSubGroupParams] = useState<ITechOperationParamWithArrayParamsDto[]>([]);
  const [arrayParamDetails, setArrayParamDetails] = useState<ITechOperationArrayParamDetailsDto[]>([]);

  const handleArrayParamChange = useCallback((value: ITechOperationArrayParamDto | null, callback?) => {
    setArrayParamDetails(value?.techOperationArrayParamsDetails || []);
    if (!callback) {
      return;
    }
    callback(value?.id);
  }, []);

  const handleParamChange = useCallback((param: ITechOperationParamWithArrayParamsDto) => {
    return (value, callback?) => {
      setScalarsArguments((prev) => ({
        ...prev,
        [param.alias]: +value,
      }));
      if (!callback) {
        return;
      }
      callback(value);
    };
  }, []);

  useEffect(() => {
    const result = {};
    techOperationsScalars.forEach((scalar) => {
      if (scalar.techOperationSubGroupId === watchFields.techOperationSubGroupId) {
        result[scalar.operationParamId] = scalar;
      }
    });
    setAvailableScalars(result);
  }, [techOperationsScalars, watchFields.techOperationSubGroupId]);

  useEffect(() => {
    if (!watchFields.techOperationSubGroupId) {
      setSubGroupParams([]);
      return;
    }

    dispatch(fetchTechOperationSubGroupParamsBySubGroupId(watchFields.techOperationSubGroupId))
      .unwrap()
      .then((data) => {
        const seen = new Set();
        const result = [...data].sort((a, b) => {
          if (!availableScalars[a.id] || Object.values(availableScalars[a.id].paramArgs).every((id) => seen.has(id))) {
            seen.add(a.id);
            return -1;
          }
          return 1;
        });
        setSubGroupParams(result);

        const formulationsList = {};
        result.forEach((param) => {
          if (!availableScalars[param.id] || !availableScalars[param.id].formulation) return;
          formulationsList[param.id] = new Formula.Parser(availableScalars[param.id].formulation);
        });
        setScalarsFormulations(formulationsList);
      });
  }, [availableScalars, dispatch, watchFields.techOperationSubGroupId]);

  useEffect(() => {
    setScalarsArguments((prev) => ({
      ...prev,
      CropType_basicHumidity: +watchFields.CropType_basicHumidity,
      CropType_basicImpurity: +watchFields.CropType_basicImpurity,
      Techoperation_fieldSize: +watchFields.fieldSize,
    }));
  }, [watchFields.CropType_basicHumidity, watchFields.CropType_basicImpurity, watchFields.fieldSize]);

  useEffect(() => {
    Object.keys(scalarsFormulations).forEach((item) => {
      const result = scalarsFormulations[item].evaluate(scalarsArguments);
      rhfMethods.setValue(`params.operationParamId-${item}.value`, _.round(result, 2));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scalarsArguments, scalarsFormulations]);

  useEffect(() => {
    if (!props.isEditingTechOperation || !watchFields.params) {
      return;
    }
    subGroupParams.forEach((param) => {
      if (Boolean(availableScalars[param.id]?.formulation)) {
        return;
      }

      const paramField = watchFields.params[`operationParamId-${param.id}`];
      if (param.techOperationArrayParams.length) {
        handleArrayParamChange(findModelByProperty(param.techOperationArrayParams, paramField.arrayParamId));
      } else {
        handleParamChange(param)(paramField.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditingTechOperation, subGroupParams]);

  return (
    <ListItem>
      <Grid container={true} spacing={2}>
        {subGroupParams.map((param) => (
          <React.Fragment key={param.id}>
            <RHFInputHidden name={`params.operationParamId-${param.id}.id`} />
            <RHFInputHidden name={`params.operationParamId-${param.id}.operationParamId`} defaultValue={param.id} />

            {param.techOperationArrayParams.length ? (
              <>
                <Grid item={true} xs={6}>
                  <RHFAutocomplete<ITechOperationArrayParamDto>
                    name={`params.operationParamId-${param.id}.arrayParamId`}
                    rules={{ required: true }}
                    defaultValue={""}
                    renderValue={(value) => findModelByProperty(param.techOperationArrayParams, value)}
                    renderOnChange={handleArrayParamChange}
                    AutocompleteProps={{
                      options: param.techOperationArrayParams,
                      getOptionLabel: (option) => option.param || "",
                      noOptionsText: "Ничего не найдено",
                    }}
                    TextFieldProps={{
                      label: param.title,
                      required: param.isRequired,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <>
                    {!arrayParamDetails.length || (
                      <RHFAutocomplete<ITechOperationArrayParamDetailsDto>
                        name={`params.operationParamId-${param.id}.value`}
                        rules={{ required: true }}
                        defaultValue={""}
                        renderValue={(value) => findModelByProperty(arrayParamDetails, value, "paramEng")}
                        renderOnChange={(val, onChange) => onChange(val?.paramEng)}
                        AutocompleteProps={{
                          options: arrayParamDetails,
                          getOptionLabel: (option) => option.param || "",
                          getOptionSelected: (option, val) => option.paramEng === val.paramEng,
                          noOptionsText: "Ничего не найдено",
                        }}
                        TextFieldProps={{
                          label: param.title,
                          required: param.isRequired,
                        }}
                      />
                    )}
                  </>
                </Grid>
              </>
            ) : Boolean(availableScalars[param.id]?.formulation) ? (
              <Grid item={true} xs={6}>
                <RHFTextField
                  name={`params.operationParamId-${param.id}.value`}
                  defaultValue={""}
                  TextFieldProps={{
                    type: "number",
                    label: param.title,
                    required: param.isRequired,
                    inputProps: {
                      readOnly: true,
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid item={true} xs={6}>
                <RHFTextField
                  name={`params.operationParamId-${param.id}.value`}
                  defaultValue={""}
                  renderOnChange={handleParamChange(param)}
                  TextFieldProps={{
                    type: "number",
                    label: param.title,
                    required: param.isRequired,
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </ListItem>
  );
};
