import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services";
import { ITechOperationAssetDto } from "../dtos/tech-operation-asset.dto";
import { ITechOperationAssetTypeDto } from "../dtos/tech-operation-assetType.dto";

class TechOperationsAssetsService extends BaseService<ITechOperationAssetDto, ILoopbackFilter> {
  private path = "Assets";
  private typesPath = "AssetTypes";

  async get(id: string): Promise<ITechOperationAssetDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(filter: ILoopbackFilter): Promise<ITechOperationAssetDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
    });
  }

  async typesList(): Promise<ITechOperationAssetTypeDto[]> {
    return this.fetch(this.typesPath, {
      method: "GET",
    });
  }

  async add(data): Promise<ITechOperationAssetDto> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async update(data): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  async delete(id: string): Promise<void> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      ...filter,
    };
  }
}

export const techOperationsAssetsService = new TechOperationsAssetsService();
