import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MapContainer } from "react-leaflet";
import { useSelector } from "react-redux";

import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { useAppbarHeight } from "../../../../shared/utils/use-app-bar-height";
import { useAppDispatch } from "../../../../store";
import { TArcgisMapCropPropsDto } from "../../shared/dtos/arcgis-map-crop-props.dto";
import { getCenter, getZoom } from "../../store/map-page.selector";
import { fetchArcGisTokenAction } from "../../store/map-page.slice";
import { FarmLandsLayer } from "../farm-lands-layer/farm-lands-layer";
import { MapDrawerPanel } from "../map-drawer-panel/map-drawer-panel";
import { MapFilterControl } from "../map-filter-control/map-filter-control";
import { OfflineMapLayer } from "../offline-map-layer/offline-map-layer";
import { useStyles } from "./map.styles";

export const MapComponent = (): JSX.Element => {
  const { appbarHeight } = useAppbarHeight();
  const classes = useStyles({ appbarHeight });
  const dispatch = useAppDispatch();
  const center = useSelector(getCenter);
  const zoom = useSelector(getZoom);

  const [feature, setFeature] = useState<TArcgisMapCropPropsDto | null>(null);

  useEffect(() => {
    dispatch(fetchArcGisTokenAction());
  }, [dispatch]);

  return (
    <CommonLayout title={"Карта"} contentPadding={0}>
      <Grid container={true} className={classes.root}>
        <Grid item={true} className={classes.navSide}>
          <MapDrawerPanel />
        </Grid>

        <Grid item={true} className={classes.mapSide}>
          <MapContainer center={center} zoom={zoom} scrollWheelZoom={true} className={classes.mapContainer}>
            <OfflineMapLayer />

            <MapFilterControl />

            {/* it shows us farm lands fields and crops */}
            <FarmLandsLayer setFeature={setFeature} />

            {feature && (
              <div className={classes.popupContainer}>
                <p>
                  <b>Подразделение: </b>
                  {feature.farm}
                </p>
                <p>
                  <b>Культура: </b>
                  {feature.crop}
                </p>
                <p>
                  <b>Поле: </b>
                  {feature.name}
                </p>
                <p>
                  <b>Площадь: </b>
                  {feature.area_2d}Га
                </p>
              </div>
            )}
          </MapContainer>
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
