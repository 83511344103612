import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { IFilter } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { normalizeFilter } from "../../../../shared/utils";
import { ICropRotationDto } from "../dtos/crop-rotation.dto";
import { IFarmLandContourDto } from "../dtos/farm-land-contour.dto";
import { IFarmLandTechDto } from "../dtos/farm-land-tech.dto";
import { IFarmLandDto } from "../dtos/farm-land.dto";

class FarmLandsService extends BaseService<IFarmLandDto, ILoopbackFilter> {
  private farmsPath = "Farms";
  private path = "FarmLands";

  async get(id: string): Promise<IFarmLandDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async getByArcGisId(id: string): Promise<IFarmLandDto> {
    const dtos = await this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: { where: { arcgisObjectId: id } } },
    });

    return dtos[0];
  }

  async list(filter: ILoopbackFilter): Promise<IFarmLandDto[]> {
    return this.fetch(this.path, { method: "GET", searchParams: { filter } });
  }

  async listByFarmId({
    farmId,
    excludeGeometry = false,
    filter,
  }: {
    farmId: string;
    excludeGeometry: boolean;
    filter?: ILoopbackFilter;
  }): Promise<IFarmLandDto[]> {
    if (!farmId) {
      return [];
    }

    return this.fetch([this.farmsPath, farmId, this.path].join("/"), {
      method: "GET",
      searchParams: {
        /**
         * TODO: fix ILoopbackFilter or fix backend
         * &filter[fields][shape]=false&filter[fields][shapeJson]=false
         * do not work (returns only 'shape' and 'shapeJson' fields at models)
         **/

        filter: JSON.stringify({
          ...filter,
          fields: {
            ...filter?.fields,
            ...(excludeGeometry ? { shape: false, shapeJson: false } : {}),
          },
        }),
      },
    });
  }

  async listCount(filter: ILoopbackFilter): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter },
    });
  }

  async cropRotationList(id: string, filter?: Partial<IFilter>): Promise<ICropRotationDto[]> {
    return this.fetch([this.path, id, "cropRotations"].join("/"), {
      method: "GET",
      searchParams: filter ? { filter: normalizeFilter(filter) } : {},
    });
  }

  async techList(id: string, filter: ILoopbackFilter): Promise<IFarmLandTechDto[]> {
    return this.fetch([this.path, id, "techOperations"].join("/"), {
      method: "GET",
      searchParams: { filter },
    });
  }

  async techListByIds(id: string, ids: string[]): Promise<IFarmLandTechDto[]> {
    return this.fetch([this.path, id, "techOperations"].join("/"), {
      method: "GET",
      searchParams: { filter: { where: { or: ids.map((id) => ({ id })) } } },
    });
  }

  async techListCount(id: string, filter: ILoopbackFilter): Promise<{ count: number }> {
    return this.fetch([this.path, id, "techOperations", "count"].join("/"), {
      method: "GET",
      searchParams: { filter },
    });
  }

  async getFarmLandContour(id: string): Promise<IFarmLandContourDto | null> {
    return this.fetch<IFarmLandContourDto>([this.path, id, "contour"].join("/"), { method: "GET" }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      return null;
    });
  }
}

export const farmLandsService = new FarmLandsService();
