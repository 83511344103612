import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import { deleteSeedsAction, fetchSeedsAction, getSelectedIds, setSearchText, setSelectedIdsAction } from "../../store";

const SeedsTable = lazy(() => import("../seeds-table/seeds-table"));

const SeedsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);
  const onDelete = useCallback(
    () => dispatch(deleteSeedsAction(selectedIds)).then(() => dispatch(fetchSeedsAction())),
    [selectedIds, dispatch]
  );
  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.SEEDS}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <SeedsTable />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default SeedsTab;
