import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IEmployeesState } from "../shared";
import { MODULE_NAME } from "./employees.slice";

// eslint-disable-next-line
export const getEmployeesState = (rootState: any): IEmployeesState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getEmployeesState, (state: IEmployeesState) => state.isLoading);
export const getList = createDraftSafeSelector(getEmployeesState, (state: IEmployeesState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getEmployeesState, (state: IEmployeesState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getEmployeesState, (state: IEmployeesState) => state.searchText);
