import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IPhenophasesState } from "../shared";
import { MODULE_NAME } from "./phenophases.slice";

// eslint-disable-next-line
export const getPhenophasesState = (rootState: any): IPhenophasesState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getPhenophasesState, (state: IPhenophasesState) => state.isLoading);
export const getList = createDraftSafeSelector(getPhenophasesState, (state: IPhenophasesState) => state.list);
export const getSelectedIds = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.searchText
);
