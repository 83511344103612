import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services";
import { ITechOperationSubGroupParamWithArrayParamsDto } from "../dtos/tech-operation-param.dto";
import { ITechOperationDto } from "../dtos/tech-operation.dto";
import { ITechOperationsInclude } from "../interfaces/tech-operations-include";

const includes: ITechOperationsInclude = {
  crop: ["name"],
  appUser: ["fullName"],
  farm: ["name"],
  farmLand: ["name"],
  techOperationGroup: ["name"],
};

class TechOperationsService extends BaseService<ITechOperationDto, ILoopbackFilter> {
  private path = "TechOperationAgregateds";

  async get(id: string): Promise<ITechOperationDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(filter: ILoopbackFilter): Promise<ITechOperationDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
    });
  }

  async listCount(filter: ILoopbackFilter): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: filter as Record<string, unknown>,
    });
  }

  async getMaxOperationNumbersByFarmLandAndTechOperationGroupIds({
    farmLandId,
    techOperationGroupId,
  }: {
    farmLandId: string;
    techOperationGroupId: string;
  }): Promise<number> {
    const maxOperationNumber = await this.fetch(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            farmLandId,
            techOperationGroupId,
          },
          order: "operationNumber DESC",
          limit: 1,
        },
      },
    });

    return maxOperationNumber[0]?.operationNumber || 0;
  }

  async getOperationsByFarmLandAndTechOperationGroupIdsAndOperationNumber({
    farmLandId,
    techOperationGroupId,
    operationNumber,
  }: {
    farmLandId: string;
    techOperationGroupId: string;
    operationNumber: number;
  }): Promise<ITechOperationDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            farmLandId,
            techOperationGroupId,
            operationNumber,
          },
        },
      },
    });
  }

  async getTechOperationSubGroupParamsWithArrayParamsBySubGroupId(
    subGroupId: string
  ): Promise<ITechOperationSubGroupParamWithArrayParamsDto[]> {
    return this.fetch("TechOperationSubGroupParams", {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            subGroupId: subGroupId,
          },
          include: {
            relation: "TechOperationParam",
            scope: {
              include: {
                relation: "techOperationArrayParams",
                scope: {
                  include: "techOperationArrayParamsDetails",
                },
              },
            },
          },
        },
      },
    });
  }

  async getTechOperationSubGroupIdsByCropTypeId(
    cropTypeId: string
  ): Promise<Record<"techOperationSubGroupId", string>[]> {
    return this.fetch("TechOperationSubGroupCropTypeRelations", {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            cropTypeId,
          },
          fields: ["techOperationSubGroupId"],
        },
      },
    });
  }

  //eslint-disable-next-line
  async add(data): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async update(data): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch(this.path, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      include: Object.keys(includes).map((item) => {
        return {
          relation: item,
          scope: { fields: includes[item] },
        };
      }),
      ...filter,
    };
  }
}

export const techOperationsService = new TechOperationsService();
