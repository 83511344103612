import { IFilter } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { normalizeFilter } from "../../../../shared/utils";
import { ICropDto } from "../dtos/crop";

class CropsService extends BaseService<ICropDto, Partial<IFilter>> {
  private path = "Crops";

  async get(id: string): Promise<ICropDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  // eslint-disable-next-line
  async add(data: ICropDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async update(data: ICropDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  async delete(id: string): Promise<string[]> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  async list(filter: Partial<IFilter>): Promise<ICropDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }

  async listCount(filter: Partial<IFilter>): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }
}

export const cropsService = new CropsService();
