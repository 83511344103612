import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { getFarmNameTuples } from "../../../../../pages/map-page/store/map-page.selector";
import { useAppDispatch } from "../../../../../store";
import { getFilter, getFilterDisabling, getInit } from "../../../store/filters.selector";
import { setFilterAction } from "../../../store/filters.slice";
import { FilterName } from "../../enums/filter-name";

const useStyles = makeStyles(() => ({
  label: {
    position: "relative",
    paddingRight: 25,
    "& + div": {
      marginTop: 0,
    },
  },
}));

export const useArcgisNameEffect = (): void => {
  const farmTuples = useSelector(getFarmNameTuples);
  const dispatch = useAppDispatch();
  const arcgisName = useSelector((state) => getFilter(state, FilterName.ArcgisFarmName));
  const farmId = useSelector((state) => getFilter(state, FilterName.FarmId));
  const isFiltersInit = useSelector(getInit);

  useEffect(() => {
    if (!farmTuples.length || !isFiltersInit) {
      return;
    }

    if (arcgisName === null) {
      dispatch(
        setFilterAction({
          filter: FilterName.ArcgisFarmName,
          value: farmTuples[0].nameArcgis,
        })
      );
      dispatch(setFilterAction({ filter: FilterName.FarmId, value: farmTuples[0].id }));
    } else {
      const tuple = farmTuples.find((item) => item.nameArcgis === arcgisName);

      if (tuple) {
        dispatch(setFilterAction({ filter: FilterName.FarmId, value: tuple.id }));
        dispatch(
          setFilterAction({
            filter: FilterName.FarmName,
            value: tuple.nameEkocrop,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [farmTuples, arcgisName, isFiltersInit]);

  // try to update arcgisName if ekocropFarmId have been changed
  useEffect(() => {
    if (!farmTuples.length || !isFiltersInit) {
      return;
    }
    const tuple = farmTuples.find((item) => item.id === farmId);

    if (!tuple) {
      return;
    }

    dispatch(
      setFilterAction({
        filter: FilterName.ArcgisFarmName,
        value: tuple.nameArcgis,
      })
    );
    // eslint-disable-next-line
  }, [farmTuples, farmId, isFiltersInit]);
};

export const FilterFieldArcgisName = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const farmTuples = useSelector(getFarmNameTuples);
  const value = useSelector((state) => getFilter(state, FilterName.ArcgisFarmName));
  const isArcgisFarmNameDisabled = useSelector((state) => getFilterDisabling(state, FilterName.ArcgisFarmName));
  const classes = useStyles();

  const onChangeHandler = useCallback(
    (event) => {
      const value = event.target.value;
      dispatch(setFilterAction({ filter: FilterName.ArcgisFarmName, value }));
    },
    // eslint-disable-next-line
    [dispatch, setFilterAction]
  );

  useArcgisNameEffect();

  return (
    <FormControl margin="normal">
      <InputLabel id="arcgis-name-label" className={classes.label}>
        Хозяйство
      </InputLabel>
      <Select
        labelId="arcgis-name-label"
        id="arcgis-name-select"
        value={value || ""}
        onChange={onChangeHandler}
        disabled={isArcgisFarmNameDisabled}
      >
        {farmTuples.map((item) => (
          <MenuItem key={item.id} value={item.nameArcgis}>
            {item.nameEkocrop}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
