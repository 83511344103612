import { IFertilisersDto } from "../dtos/fertilisers";
import { Units } from "../enums/units";

export class Fertiliser {
  readonly id: string;
  name: string;
  type: string;
  unit: typeof Units[keyof typeof Units];

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IFertilisersDto {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      unit: this.unit,
    };
  }

  updateFromDto(dto: IFertilisersDto): void {
    this.name = dto.name;
    this.type = dto.type;
    this.unit = dto.unit;
  }
}
