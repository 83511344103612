import { Card, CardActions, CardMedia, Fab, Grid } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React from "react";

import { useStyles } from "./observation-photos-upload-preview.styles";

interface IProps {
  src: string;
  onDelete: () => void;
}

export const PhotoUploadPreview = (props: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item={true} xs={12} sm={6} md={4} lg={2}>
      <Card className={classes.card}>
        <CardMedia image={props.src} className={classes.media}></CardMedia>
        <CardActions className={classes.actions}>
          <Fab onClick={props.onDelete} size={"medium"}>
            <DeleteIcon />
          </Fab>
        </CardActions>
      </Card>
    </Grid>
  );
};
