import { ThemeOptions } from "@material-ui/core/styles";

import { SELECTED_BLUE_COLOR, THEME_PRIMARY_MAIN_COLOR } from "./mui-theme-constants";

export const overrides: ThemeOptions["overrides"] = {
  MuiCheckbox: {
    colorSecondary: {
      "&$checked": {
        color: SELECTED_BLUE_COLOR,
      },
    },
  },
  MuiDrawer: {
    paperAnchorDockedLeft: {
      borderRight: 0,
    },
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: 0,
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: "#f44336",
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: "45px",
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderColor: "#707070",
    },
  },
  MuiTab: {
    textColorInherit: {
      borderLeft: "4px solid #a3a3a3",
      borderBottom: "1px solid #f7f9fc",
      "&$selected": {
        borderLeftColor: THEME_PRIMARY_MAIN_COLOR,
        backgroundColor: "rgba(112, 157, 75, 0.20)",
      },
    },
    wrapper: {
      alignItems: "start",
      textAlign: "start",
    },
  },
  MuiTableCell: {
    root: {
      height: "48px",
      borderBottom: "1px solid #a3a3a3",
    },
    sizeSmall: {
      "&:last-child": {
        paddingRight: 0,
        padding: "2px",
      },
    },
    stickyHeader: {
      backgroundColor: "#fff",
    },
  },
  MuiTableRow: {
    root: {
      "&$hover": {
        "&:hover": {
          backgroundColor: "rgba(112, 157, 75, 0.1);",
        },
      },
    },
  },
  MuiTabs: {
    root: {
      background: "#f7f9fc",
      // minHeight: 300, TODO: find out why minHeight must be 300px
    },
    flexContainer: {
      background: "#ffffff",
    },
  },
};
