import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ISeedsState } from "../shared";
import { MODULE_NAME } from "./seeds.slice";

// eslint-disable-next-line
export const getSeedsState = (rootState: any): ISeedsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getSeedsState, (state: ISeedsState) => state.isLoading);
export const getList = createDraftSafeSelector(getSeedsState, (state: ISeedsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getSeedsState, (state: ISeedsState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getSeedsState, (state: ISeedsState) => state.searchText);
