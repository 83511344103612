import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFarmLandsState } from "../shared/interfaces/farm-lands-state";
import { MODULE_NAME } from "./farm-lands.slice";

// eslint-disable-next-line
export const getFarmLandsState = (rootState: any): IFarmLandsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getFarmLandsState, (state: IFarmLandsState) => state.isLoading);
export const getList = createDraftSafeSelector(getFarmLandsState, (state: IFarmLandsState) => state.list);
export const getListCount = createDraftSafeSelector(getFarmLandsState, (state: IFarmLandsState) => state.listCount);
export const getDetails = createDraftSafeSelector(getFarmLandsState, (state: IFarmLandsState) => state.item);
