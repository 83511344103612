// TODO: move content of this file to root dependencies
import { stringify } from "qs";

import { API_URL } from "../../config/api";

// eslint-disable-next-line
type TAbstractFilter = Record<string, any>;
type TExtendedRequestInit<T> = RequestInit &
  Partial<{
    searchParams: Record<string, unknown> & { filter?: T };
    baseUrl: string;
  }>;

export abstract class BaseService<TDto, T extends TAbstractFilter> {
  public readonly ROOT_URL: string = process.env.REACT_APP_API_ENDPOINT || API_URL || ""; // TODO: remove const URL later

  abstract list(filter: T, include: unknown): Promise<TDto[]>;

  abstract get(id: string): Promise<TDto>;

  async fetch<TResponse = TDto, R = T>(path: string, init: TExtendedRequestInit<R> = {}): Promise<TResponse> {
    const { baseUrl = this.ROOT_URL, searchParams, ...nativeInit } = init;
    const url = [baseUrl, path].join("/");

    const access_token = localStorage.getItem("access_token"); // TODO: change this behavior (use DI or store)
    const urlWithSearch = `${url}?${stringify({
      access_token,
      ...searchParams,
    })}`;
    return fetch(urlWithSearch, nativeInit)
      .then((response) => {
        // TODO: handle errors globally
        if (response.ok) {
          return response;
        }
        throw Error(response.statusText);
      })
      .then((response) => response.json());
  }
}
