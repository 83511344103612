import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import lodashGet from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { Controller, ControllerRenderProps, RegisterOptions, useFormContext } from "react-hook-form";

// TODO: type with @material-ui/lab/AutocompleteProps
interface IAutocompleteProps<T> {
  disabled?: boolean;
  options: T[];
  getOptionLabel?: (option: T) => string;
  getOptionSelected?: (option: T, val: T) => boolean;
  noOptionsText?: string;
}

interface IProps<T> {
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  renderValue?: (val: string) => T | null;
  renderOnChange?: (val: T | null, onChange: ControllerRenderProps["onChange"]) => void;
  AutocompleteProps: IAutocompleteProps<T>;
  TextFieldProps?: Partial<TextFieldProps>;
}

// eslint-disable-next-line
export const RHFAutocomplete = <T extends Record<string, any>>(props: IProps<T>) => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, props.name)),
    [props, rhfMethods.formState.errors]
  );

  const getOptionLabel = useCallback((option: T) => option.name || "", []);
  const getOptionSelected = useCallback((option, val) => option.id === val.id, []);

  const renderInput = useCallback(
    (params) => <TextField error={error} {...props.TextFieldProps} {...params} />,
    [error, props]
  );

  const filterOptions = createFilterOptions<T>({
    limit: 100,
  });

  const renderAutocomplete = useCallback(
    ({ onChange, value }) => (
      <Autocomplete<T>
        fullWidth={true}
        value={props.renderValue ? props.renderValue(value) : value}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={(_, val) => (!props.renderOnChange ? onChange(val?.id) : props.renderOnChange(val, onChange))}
        renderInput={renderInput}
        noOptionsText={"Ничего не найдено"}
        filterOptions={filterOptions}
        {...props.AutocompleteProps}
      />
    ),
    [filterOptions, getOptionLabel, getOptionSelected, props, renderInput]
  );

  return (
    <Controller
      name={props.name}
      control={rhfMethods.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={renderAutocomplete}
    />
  );
};
