import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { PATHS } from "../../../../constant";
import { getCropTypesList } from "../../../../modules/crop-types";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { getInfoPhenoPhases, getInfoSeasons } from "../../../../modules/info-data";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFDateTimePicker } from "../../../../shared/components/react-hook-form-mui/datetimepicker";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import { farmLandsService } from "../../../fields/shared/services/farm-lands.service";
import { ObservationPhotosUpload } from "../../components/observation-photos-upload/observation-photos-upload";
import { ObservationViolationsList } from "../../components/observation-violations-list/observation-violations-list";
import { IPhenoPhaseDto } from "../../shared/dtos/phenophase.dto";
import { IObservationFormData } from "../../shared/interfaces/observation-form-data";
import { Observation } from "../../shared/models/observations.models";
import { getPhotosDirty } from "../../store/observation-editing.selector";
import {
  addObservationAction,
  fetchObservationByIdAction,
  updateObservationAction,
  uploadChosenPhotos,
} from "../../store/observation-editing.slice";

export const ObservationsEditingForm = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const sourceFarmLandId = query.get("fieldId");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState<{
    type: AlertProps["severity"];
    text: JSX.Element | string;
  } | null>(null);
  const appFilterFarmId = useSelector((state) => getFilter(state, FilterName.FarmId));
  const appFilterSeasonId = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const [farmLands, setFarmLands] = useState<IFarmLandDto[]>([]);
  const cropTypes = useSelector(getCropTypesList);
  const phenoPhases = useSelector(getInfoPhenoPhases);
  const seasons = useSelector(getInfoSeasons);
  const isPhotosDirty = useSelector(getPhotosDirty);
  const [seasonDates, setSeasonDates] = useState<{
    startedAt?: number;
    finishedAt?: number;
  }>({});
  const [photosUploadKey, setPhotosUploadKey] = useState<number>(0);

  const rhfMethods = useForm<IObservationFormData>({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: new Observation().setFarmLandId(sourceFarmLandId).asFormData,
  });

  const watchFields = rhfMethods.watch();

  // Utility getters

  const getSeasonDates = useCallback(
    (seasonId) => {
      const currentSeason = findModelByProperty(seasons, seasonId);
      setSeasonDates({
        startedAt: currentSeason?.startedAt,
        finishedAt: currentSeason?.finishedAt,
      });
    },
    [seasons]
  );

  // Update lists

  const updateFarmLandsList = async (farmId) => {
    const list = !farmId ? [] : await farmLandsService.listByFarmId({ farmId, excludeGeometry: true });
    list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
    setFarmLands(list);
  };

  useEffect(() => {
    getSeasonDates(appFilterSeasonId);
  }, [appFilterSeasonId, getSeasonDates]);

  const isEditingObservation = Boolean(id) && id !== PATHS.EDIT_NEW_PAGE;

  useEffect(() => {
    (async () => {
      if (!appFilterFarmId || !appFilterSeasonId) {
        return;
      }

      updateFarmLandsList(appFilterFarmId);

      if (!isEditingObservation) {
        const timestamp = new Date().getTime();
        rhfMethods.setValue("inputDate", timestamp);
        rhfMethods.setValue("farmId", appFilterFarmId);
        rhfMethods.setValue("seasonId", appFilterSeasonId);

        return;
      }

      if (!id) {
        return;
      }
      const model = await dispatch(fetchObservationByIdAction(id)).unwrap();
      rhfMethods.reset(model.asFormData);

      if (model.seasonId) {
        getSeasonDates(model.seasonId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFilterFarmId, appFilterSeasonId, id]);

  const onSubmit = useCallback(
    async (data) => {
      const newDto = new Observation(data.id);
      newDto.updateFromFormData(data);
      newDto.setAppUserId(localStorage.getItem("user_id") as string);

      try {
        const model = await dispatch(
          isEditingObservation ? updateObservationAction(newDto) : addObservationAction(newDto)
        ).unwrap();

        rhfMethods.reset(model.asFormData);

        dispatch(uploadChosenPhotos()).then(async () => {
          model.completeReport();

          const newModel = await dispatch(updateObservationAction(model)).unwrap();
          setPhotosUploadKey((key) => key + 1);

          if (!isEditingObservation) {
            navigate(`../${newModel.id}`, {
              relative: "path", // navigate relative to path (not router tree)
              replace: true,
            });
          }
        });
      } catch (err) {
        throw err;
      }
    },
    [dispatch, navigate, isEditingObservation, rhfMethods]
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onMessageHide = useCallback((_, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage(null);
  }, []);

  useEffect(() => {
    (async () => {
      if (!farmLands.length || !watchFields.farmLandId) {
        return;
      }

      const farmLand = findModelByProperty(farmLands, watchFields.farmLandId);

      if (rhfMethods.formState.touched.farmLandId || rhfMethods.formState.dirtyFields.farmLandId) {
        rhfMethods.formState.touched.farmLandId = true;
        rhfMethods.setValue("cropTypeId", farmLand?.cropTypeId);
        rhfMethods.setValue("phenoPhaseId", null);
        rhfMethods.setValue("violations", []);
      }
    })();
    // eslint-disable-next-line
  }, [farmLands, watchFields.farmLandId]);

  const chosenFarmLandCropName = useMemo(() => {
    const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    const chosenFarmLandCrop = findModelByProperty(cropTypes, chosenFarmLand?.cropTypeId);
    return chosenFarmLandCrop?.name || "";
  }, [cropTypes, farmLands, watchFields.farmLandId]);

  const availablePhenoPhases = useMemo(() => {
    if (!watchFields.farmLandId || !phenoPhases.length) {
      return [];
    }
    const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    return phenoPhases.filter((item) => item.cropTypeId === chosenFarmLand?.cropTypeId).sort((a, b) => a.name - b.name);
  }, [farmLands, phenoPhases, watchFields.farmLandId]);

  const isSubmitDisabled = useMemo(() => {
    return !rhfMethods.formState.isDirty && !isPhotosDirty;
  }, [rhfMethods.formState, isPhotosDirty]);

  return (
    <CommonLayout title={"Отчёты осмотров"}>
      <Paper>
        <FormProvider {...rhfMethods}>
          <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
            <input type="hidden" name="id" ref={rhfMethods.register} />
            <input type="hidden" name="farmId" ref={rhfMethods.register} />
            <input type="hidden" name="seasonId" ref={rhfMethods.register} />
            <input type="hidden" name="cropTypeId" ref={rhfMethods.register} />

            <List>
              <ListItem>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <RHFAutocomplete<IFarmLandDto>
                      name="farmLandId"
                      rules={{ required: true }}
                      renderValue={(value) => findModelByProperty(farmLands, value)}
                      AutocompleteProps={{
                        disabled: !farmLands.length || Boolean(sourceFarmLandId),
                        options: farmLands,
                        noOptionsText: "В хозяйстве нет полей",
                      }}
                      TextFieldProps={{
                        label: "Поле",
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Box pt={3}>{chosenFarmLandCropName}</Box>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <RHFAutocomplete<IPhenoPhaseDto>
                      name="phenoPhaseId"
                      rules={{ required: true }}
                      renderValue={(value) => findModelByProperty(phenoPhases, value)}
                      AutocompleteProps={{
                        disabled: !watchFields.farmLandId,
                        options: availablePhenoPhases,
                        noOptionsText: "Нет стадий роста",
                      }}
                      TextFieldProps={{
                        label: "Стадия роста",
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <RHFDateTimePicker
                      name="inputDate"
                      DateTimePickerProps={{
                        minDate: seasonDates?.startedAt,
                        maxDate: seasonDates?.finishedAt,
                        label: "Дата отчета",
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <RHFTextField
                  name={"comment"}
                  TextFieldProps={{
                    label: "Комментарий",
                  }}
                />
              </ListItem>

              <ObservationViolationsList />

              <ListItem>
                <Typography variant={"h5"}>Фотографии отчета</Typography>
              </ListItem>

              <Box px={2} py={1}>
                <ObservationPhotosUpload key={photosUploadKey} />
              </Box>
            </List>

            <Divider />

            <Box p={1}>
              <Grid container={true} justify={"center"} spacing={2}>
                <Grid item={true}>
                  <Button type="submit" color={"primary"} variant={"contained"} disabled={isSubmitDisabled}>
                    Сохранить
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button color={"primary"} variant={"outlined"} onClick={onCancel}>
                    Отмена
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>

        {message && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={onMessageHide}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={message.type}>{message.text}</Alert>
          </Snackbar>
        )}
      </Paper>
    </CommonLayout>
  );
};
