import { v4 as uuidv4 } from "uuid";

import {
  ITechOperationArrayParamDto,
  ITechOperationParamWithArrayParamsDto,
  ITechOperationValue,
} from "../dtos/tech-operation-param.dto";

export class TechOperationValue {
  readonly id: string;
  farmId: string;
  techOperationId: string;
  operationParamId: ITechOperationParamWithArrayParamsDto["id"];
  arrayParamId: ITechOperationArrayParamDto["id"];
  value: string;

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  get asDto(): ITechOperationValue {
    return {
      id: this.id,
      farmId: this.farmId,
      techOperationId: this.techOperationId,
      operationParamId: this.operationParamId,
      arrayParamId: this.arrayParamId,
      value: this.value,
    };
  }

  updateFromDto(dto: ITechOperationValue): void {
    this.farmId = dto.farmId;
    this.techOperationId = dto.techOperationId;
    this.operationParamId = dto.operationParamId;
    this.arrayParamId = dto.arrayParamId;
    this.value = dto.value;
  }

  get asFormData(): ITechOperationValue {
    return {
      id: this.id,
      arrayParamId: this.arrayParamId,
      farmId: this.farmId,
      operationParamId: this.operationParamId,
      techOperationId: this.techOperationId,
      value: this.value,
    };
  }

  updateFromFormData(data: ITechOperationValue): TechOperationValue {
    this.farmId = data.farmId;
    this.techOperationId = data.techOperationId;
    this.operationParamId = data.operationParamId;
    this.arrayParamId = data.arrayParamId;
    this.value = data.value;
    return this;
  }

  setFarmId(farmId: string): TechOperationValue {
    this.farmId = farmId;
    return this;
  }

  setTechOperationId(techOperationId: string): TechOperationValue {
    this.techOperationId = techOperationId;
    return this;
  }
}
