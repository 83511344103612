import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useAppDispatch } from "../../../../store";
import { TechOperationAssetsGroup } from "../../components/tech-operation-editing-assets-group/tech-operation-editing-assets-group";
import { TechOperationAssetEntityID } from "../../shared/enums/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { fetchAssetTypesAction, fetchAssetsByTechOperationIdAction } from "../../store/tech-operation-editing.slice";

interface IProps {
  techOperationId?: string;
  // eslint-disable-next-line
  techOperationSubGroup: any;
}

export const TechOperationEditingAssets = (props: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const rhfMethods = useFormContext();

  const { fields, append, remove } = useFieldArray<ITechOperationAssetFormData>({
    name: "assets",
    control: rhfMethods.control,
  });

  useEffect(() => {
    dispatch(fetchAssetTypesAction());
    if (!props.techOperationId) {
      return;
    }
    dispatch(fetchAssetsByTechOperationIdAction(props.techOperationId));
  }, [dispatch, props.techOperationId]);

  return !props.techOperationSubGroup.usesChemicals &&
    !props.techOperationSubGroup.usesFertilisers &&
    !props.techOperationSubGroup.usesSeeds ? (
    <></>
  ) : (
    <>
      {props.techOperationSubGroup.usesChemicals && (
        <TechOperationAssetsGroup
          title="СЗР и норма внесения на гектар"
          optionsLabel="Тип вносимых СЗР"
          noOptionsText="Нет доступных СЗР"
          addButtonText="Добавить СЗР"
          entityType={TechOperationAssetEntityID.CHEMICAL}
          assetsList={fields}
          onAssetAppend={append}
          onAssetRemove={remove}
        />
      )}

      {props.techOperationSubGroup.usesFertilisers && (
        <TechOperationAssetsGroup
          title="Удобрения и норма внесения на гектар"
          optionsLabel="Тип вносимых удобрений"
          noOptionsText="Нет доступных удобрений"
          addButtonText="Добавить удобрения"
          entityType={TechOperationAssetEntityID.FERTILISER}
          assetsList={fields}
          onAssetAppend={append}
          onAssetRemove={remove}
        />
      )}

      {props.techOperationSubGroup.usesSeeds && (
        <TechOperationAssetsGroup
          title="Семена"
          optionsLabel="Тип используемых семян"
          noOptionsText="Нет доступных семян"
          addButtonText="Добавить семена"
          entityType={TechOperationAssetEntityID.SEED}
          assetsList={fields}
          onAssetAppend={append}
          onAssetRemove={remove}
        />
      )}
    </>
  );
};
