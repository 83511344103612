import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles<Theme, { appbarHeight: number }>((theme: Theme) => ({
  root: {
    height: ({ appbarHeight }) => `calc(100vh - ${appbarHeight}px)`,
  },
  navSide: {
    height: "inherit",
    overflow: "auto",
  },
  mapSide: {
    flexGrow: 1,
    height: "inherit",
  },
  mapContainer: {
    height: "100%",
    width: "100%",
  },
  popupContainer: {
    background: theme.palette.background.default,
    zIndex: 1000,
    position: "absolute",
    width: "auto",
    bottom: 0,
    margin: "15px",
    padding: "5px 10px",
    borderRadius: "5px",
  },
}));
