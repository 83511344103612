import * as Highcharts from "highcharts";

import { IFilter } from "../../../../shared/interface/filter";
import { BaseService } from "../../../../shared/services/base.service";
import { IFarmLandsWeatherFilter } from "../interfaces/farm-lands-weather-filter";

// eslint-disable-next-line
class FieldClimateService extends BaseService<any, Partial<IFilter>> {
  private path = "FieldClimateGateways/request";
  private field_climate_endpoint: string = process.env.REACT_APP_FIELD_CLIMATE_API_ENDPOINT || "";

  // eslint-disable-next-line
  list(filter: Partial<IFilter>): Promise<any[]> {
    throw new Error("Method not implemented.");
  }

  // eslint-disable-next-line
  get(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async getCharts({ stationId, from, to, period }: IFarmLandsWeatherFilter): Promise<Array<Highcharts.Options>> {
    const url = [
      this.field_climate_endpoint,
      `chart/highchart/${stationId}/${period}/from/${Math.floor(from.getTime() / 1000)}/to/${Math.floor(
        to.getTime() / 1000
      )}`,
    ].join("/");

    return this.fetch(this.path, {
      method: "GET",
      headers: [["accept-language", "ru"]],
      searchParams: { url },
    })
      .then((result: Array<Highcharts.Options>) => (Array.isArray(result) ? result : []))
      .catch(() => []);
  }
}

export const fieldClimateService = new FieldClimateService();
