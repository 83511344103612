import React, { PropsWithChildren } from "react";

import { Role } from "../../../shared/enums/role";
import { getRole } from "../../../utils/hooks/use-role/use-role";

export const Permissions = (props: PropsWithChildren<{ only?: Role; some?: Role[]; every?: Role[] }>): JSX.Element => {
  const { children, only = false, some = false, every = false } = props;

  const [role, setRole] = React.useState<Role | null>(null);
  React.useEffect(() => {
    getRole((roleName) => setRole(roleName as Role)); // TODO: use Role definition in all pages
  }, [role]);

  if (role === null) {
    return <></>;
  }

  if (only) {
    return role === only ? <>{children}</> : <></>;
  }

  if (some) {
    return some.includes(role) ? <>{children}</> : <></>;
  }

  // TODO: implement every logic when user can use multiple roles
  if (every) {
    return <></>;
  }

  return <></>;
};
