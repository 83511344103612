import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { GeoJSON as GeoJSONComponent, MapContainer, MapContainerProps, TileLayer } from "react-leaflet";

import { useAppDispatch } from "../../../../store";
import { TBriefCropGeoJson } from "../../../map-page/shared/services/arcgis-crops.service";
import { useCropStyles } from "../../../map-page/shared/utils/use-crop-styles";
import { getBriefCropAction } from "../../../map-page/store/map-page.slice";
import { FarmLand } from "../../shared/models/farm-land";

interface IProps extends MapContainerProps {
  model: FarmLand;
  height?: number | string; // could be 100px, 30vh, calc(100vh - 30px)...
}

export const FarmLandBriefMap = (props: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { style } = useCropStyles();
  const {
    model: { arcgisObjectId: id },
    height = 150,
    ...other
  } = props;
  const [geoJson, setGeoJson] = useState<TBriefCropGeoJson | null>(null);

  useEffect(() => {
    dispatch(getBriefCropAction(id))
      .unwrap()
      .then((result) => setGeoJson(result));
  }, [dispatch, id]);

  return (
    <Box
      height={height}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ccc",
      }}
    >
      {geoJson && geoJson.getLayers().length ? (
        <MapContainer
          style={{ height, width: "100%" }}
          placeholder={<Skeleton height={height} variant="rect" placeholder="Loading..." />}
          bounds={geoJson.getBounds()}
          scrollWheelZoom={false}
          zoomControl={false}
          attributionControl={false}
          dragging={false}
          {...other}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSONComponent data={geoJson.toGeoJSON()} style={style} />
        </MapContainer>
      ) : (
        <span>Карта поля не заведена</span>
      )}
    </Box>
  );
};
