import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";
import lodashGet from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

interface IProps<T> {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  rules?: RegisterOptions;
  SelectProps?: SelectProps;
  options: T[];
  renderOnChange?: (
    evt: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

export const RHFSelect = <T extends string | number>(props: IProps<T>): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, props.name)),
    [props, rhfMethods.formState.errors]
  );

  const labelId = useMemo(() => props.name + "-label", [props.name]);

  const renderSelectField = useCallback(
    ({ onChange, value }) => (
      <FormControl fullWidth={true} disabled={props.disabled} error={error}>
        {props.label && <InputLabel id={labelId}>{props.label}</InputLabel>}
        <Select
          labelId={labelId}
          value={value || ""}
          onChange={(evt) => (props.renderOnChange ? props.renderOnChange(evt) : onChange(evt.target.value))}
          {...props.SelectProps}
        >
          {props.options.map((option, idx) => (
            <MenuItem key={`${idx}.${option}`} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [error, props, labelId]
  );

  return (
    <Controller
      name={props.name}
      control={rhfMethods.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={renderSelectField}
    />
  );
};
