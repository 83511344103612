import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CropTypeModel, ICropTypesState, cropTypesService } from "../shared";

const initialState: ICropTypesState = {
  isLoading: false,
  list: [],
};
export const MODULE_NAME = "cropTypes";
export const cropTypesSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<CropTypeModel[]>): void {
      state.list = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCropTypesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchCropTypesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCropTypesAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const cropTypesReducer = cropTypesSlice.reducer;
export const { setListAction } = cropTypesSlice.actions;

export const fetchCropTypesAction = createAsyncThunk(
  `${MODULE_NAME}/fetchCropTypes`,
  async (params, { getState, dispatch }) => {
    const dtos = await cropTypesService.list();
    const models = dtos.map((dto) => {
      const model = new CropTypeModel(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
  }
);
