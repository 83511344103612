import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PATHS } from "../../../../constant";
import { TechOperationsEditingPage } from "../tech-operations-editing/tech-operations-editing";

const TechOperationsList = lazy(() => import("../tech-operations-list/tech-operations-list"));

export const TechOperations = (): JSX.Element => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index={true} element={<TechOperationsList />} />
        <Route path={`${PATHS.EDIT_PAGE}/:id`} element={<TechOperationsEditingPage />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </Suspense>
  );
};
