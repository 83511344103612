import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { logAction } from "../../errors";
import { DiseaseModel, IDiseasesState, diseasesService } from "../shared";

const initialState: IDiseasesState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const MODULE_NAME = "diseases";
export const diseasesSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<DiseaseModel[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDiseasesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchDiseasesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchDiseasesAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const diseasesReducer = diseasesSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } = diseasesSlice.actions;

export const fetchDiseasesAction = createAsyncThunk(`${MODULE_NAME}/fetchDiseases`, async (params, { dispatch }) => {
  const dtos = await diseasesService.list({});
  const models = dtos.map((dto) => {
    const model = new DiseaseModel(dto.id);
    model.updateFromDto(dto);
    return model;
  });
  dispatch(setListAction(models));
});

export const deleteDiseasesAction = createAsyncThunk<Promise<void>, string[]>(
  `${MODULE_NAME}/deleteCropsAction`,
  async (ids, { dispatch }) => {
    const selectedToDeletePromises = ids.map((id) => diseasesService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      dispatch(logAction("Something went wrong"));
    }
  }
);
