import { ICropDto } from "../dtos/crop";

export class Crop {
  readonly id: string;
  name: string;
  color: string;
  shortName: string;
  description: string;
  category: string;
  subCategory: string;
  cropCode1c: string;
  basicHumidity?: number;
  basicImpurity?: number;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ICropDto {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      shortName: this.shortName,
      description: this.description,
      category: this.category,
      subCategory: this.subCategory,
      cropCode1c: this.cropCode1c,
      basicHumidity: this.basicHumidity,
      basicImpurity: this.basicImpurity,
    };
  }

  updateFromDto(dto: ICropDto): void {
    this.name = dto.name;
    this.color = dto.color;
    this.shortName = dto.shortName;
    this.description = dto.description;
    this.category = dto.category;
    this.subCategory = dto.subCategory;
    this.cropCode1c = dto.cropCode1c;
    this.basicHumidity = dto.basicHumidity;
    this.basicImpurity = dto.basicImpurity;
  }
}
