import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import { setSearchText } from "../../store";
import DiseasesTab from "../diseases-tab/diseases-tab";

export const Diseases = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearchText(""));
  }, [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="*" element={<DiseasesTab />} />
      </Routes>
    </Suspense>
  );
};
