import { Feature, GeometryObject } from "geojson";
import { GeoJSON as LeafletGeoJSON } from "leaflet";
import React, { useCallback, useEffect, useRef } from "react";
import { GeoJSON, GeoJSONProps } from "react-leaflet";

/**
 * GeoJsonWithUpdates is a wrapper around react-leaflet's GeoJSON component to support data changes
 * See https://github.com/PaulLeCam/react-leaflet/issues/332
 *
 * It accepts the same props like react-leaflet's GeoJSON component.
 * However, updates are only support
 */
export const GeoJsonWithUpdates = (props: GeoJSONProps): JSX.Element => {
  const geoJsonLayerRef = useRef<LeafletGeoJSON | null>(null);
  const { data, pathOptions, style, filter } = props;

  // filter function in GeoJSON is immutable (here we do it mutable)
  //eslint-disable-next-line
  const filterRef = useRef<(feature: Feature<GeometryObject, any>) => boolean>((feature: Feature) => true);
  if (filter) {
    filterRef.current = filter;
  }
  const filterCallback = useCallback((feature: Feature) => {
    return filterRef.current(feature);
  }, []);

  // rerender data when properties changes
  useEffect(() => {
    const layer = geoJsonLayerRef.current;
    if (!layer) {
      return;
    }

    layer.clearLayers().addData(props.data);
    // clearLayers() seems to remove the `pathOptions`, `style` and `interactive` prop as well
    // Resetting it here
    if (props.pathOptions) {
      layer.setStyle(props.pathOptions);
    } else if (props.style) {
      layer.setStyle(props.style);
    }
    //eslint-disable-next-line
  }, [data, pathOptions, style, filter]);

  return <GeoJSON {...props} filter={filterCallback} ref={geoJsonLayerRef} />;
};
