import { Tab, Tabs } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const FarmLandsSubmenu = (): JSX.Element => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.pathname.split("/")[3] || "");
  const handleChange = useCallback((event, newValue) => setTabValue(newValue), [setTabValue]);

  return (
    <Tabs orientation={"vertical"} value={tabValue} onChange={handleChange} aria-label="farm lands submenu">
      <Tab component={Link} to={"."} state={{ tabValue: "" }} value="" label="Основная информация" />
      <Tab component={Link} to={"tech"} state={{ tabValue: "tech" }} value="tech" label="Техоперации" />
      <Tab component={Link} to={"reports"} state={{ tabValue: "reports" }} value="reports" label="Отчеты осмотров" />
    </Tabs>
  );
};
