import { Control } from "leaflet";

export function updateControlTitles(control: Control): void {
  const container = control.getContainer();
  if (container) {
    const downloadElement = container.firstChild as HTMLLinkElement;
    const removeElement = container.lastChild as HTMLLinkElement;
    downloadElement.title = "Сохранить карты";
    removeElement.title = "Удалить карты";
  }
}
