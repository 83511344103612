interface IConfig {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

export const serviceWorkerConfig: IConfig = {
  onSuccess: (registration) => {
    // eslint-disable-next-line no-console
    console.log("onSuccess: ", registration);
  },
  onUpdate: (registration) => {
    // eslint-disable-next-line no-console
    console.log("onSuccess: ", registration);
  },
};
