import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IObservationEditingState } from "../shared/interfaces/observation-editing-state";
import { IObservationPhoto } from "../shared/models/observation-photo.model";
import { Observation } from "../shared/models/observations.models";
import { observationsService } from "../shared/services/observations.service";
import { getObservationPhotosState } from "./observation-editing.selector";

const initialState: IObservationEditingState = {
  oldPhotos: [],
  chosenPhotos: [],
  observation: new Observation(),
};
export const MODULE_NAME = "ObservationEditing";
export const observationEditingSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setObservationData(state, action: PayloadAction<Observation>): void {
      state.observation = action.payload;
    },
    setOldPhotosAction(state, action: PayloadAction<IObservationPhoto[]>): void {
      state.oldPhotos = action.payload;
    },
    deleteOldPhotoAction(state, action: PayloadAction<string>): void {
      state.oldPhotos = state.oldPhotos.filter((photo) => photo.id !== action.payload);
    },
    setChosenPhotosAction(state, action: PayloadAction<File[]>): void {
      state.chosenPhotos = action.payload;
    },
    deleteChosenPhotoAction(state, action: PayloadAction<number>): void {
      state.chosenPhotos = [...state.chosenPhotos.filter((_, idx) => idx !== action.payload)];
    },
    clearPhotosListsAction(state): void {
      state.oldPhotos = [];
      state.chosenPhotos = [];
    },
  },
});

export const observationEditingReducer = observationEditingSlice.reducer;
export const {
  setObservationData,
  setOldPhotosAction,
  deleteOldPhotoAction,
  setChosenPhotosAction,
  deleteChosenPhotoAction,
  clearPhotosListsAction,
} = observationEditingSlice.actions;

export const fetchObservationByIdAction = createAsyncThunk<Observation, string>(
  `${MODULE_NAME}`,
  async (observationId, { dispatch }) => {
    const dto = await observationsService.get(observationId);
    const model = new Observation(observationId);
    model.updateFromDto(dto);
    dispatch(fetchObservationPhotosAction([observationId]));
    dispatch(setObservationData(model));
    return model;
  }
);

export const addObservationAction = createAsyncThunk<Observation, Observation>(
  `${MODULE_NAME}/addObservation`,
  async (data, { dispatch }) => {
    const dto = await observationsService.add(data);
    const newModel = new Observation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setObservationData(newModel));
    return newModel;
  }
);

export const updateObservationAction = createAsyncThunk<Observation, Observation>(
  `${MODULE_NAME}/updateObservation`,
  async (data, { dispatch }) => {
    const dto = await observationsService.update(data);
    const newModel = new Observation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setObservationData(newModel));
    return newModel;
  }
);

export const fetchObservationPhotosAction = createAsyncThunk<void, string[]>(
  `${MODULE_NAME}/getObservationPhotos`,
  async (filter, { dispatch }) => {
    const photosIds = await observationsService.getObservationPhotosIds(filter);
    const photos = photosIds.map(async (item) => ({
      model: item,
      value: await observationsService.getObservationPhotoById(item.id),
    }));

    //eslint-disable-next-line
    const res = await Promise.all<any>(photos);
    const result = res.map((photo) => {
      return {
        id: photo.model.id,
        src: photo.value.image_data,
      };
    });
    dispatch(setOldPhotosAction(result));
    dispatch(setChosenPhotosAction([]));
  }
);

export const deleteExistingPhotoAction = createAsyncThunk<Promise<void>, string>(
  `${MODULE_NAME}/deleteObservationPhoto`,
  async (id, { dispatch, getState }) => {
    const observationId = getObservationPhotosState(getState()).observation.id;
    return observationsService
      .deletePhoto(id)
      .then(() => {
        dispatch(deleteOldPhotoAction(id));
        dispatch(fetchObservationPhotosAction([observationId]));
      })
      .catch((err) => {
        throw err;
      });
  }
);

export const uploadChosenPhotos = createAsyncThunk<Promise<void>, void>(
  `${MODULE_NAME}/uploadObservationPhoto`,
  async (_, { dispatch, getState }) => {
    const observationId = getObservationPhotosState(getState()).observation.id;
    const chosenPhotos = getObservationPhotosState(getState()).chosenPhotos;
    if (!chosenPhotos.length) {
      return;
    }
    return observationsService
      .uploadPhotos(observationId, chosenPhotos)
      .then(() => {
        dispatch(setChosenPhotosAction([]));
        dispatch(fetchObservationPhotosAction([observationId]));
      })
      .catch((err) => {
        throw err;
      });
  }
);
