import { IGeoFeatureLayerDto } from "../../../../shared/dtos/geo-feature-layer.dto";
import { BaseArcgisService } from "../../../../shared/services/base-arcgis.service";
import { FarmLandCropsOutField } from "../enums/farm-land-crops-out-field";
import { ICropAttributes, IFarmLandCropsGeoFeatureAttributes } from "../models/farm-land-crops-geo-feature";

// TODO: need to remove this service at all
class FarmLandCropsGeoService extends BaseArcgisService {
  //protected geoServiceUrl = `${this.geoServiceUrl}/services/Common_Layers/Культура_сезона_2022/MapServer/0`;
  protected path = `crops/MapServer/0`;

  async geoFeaturesList(farmName: string): Promise<IGeoFeatureLayerDto<IFarmLandCropsGeoFeatureAttributes>> {
    return this.list({
      where: `farm='${farmName}'`,
      returnGeometry: true,
      outFields: [
        FarmLandCropsOutField.Farm,
        FarmLandCropsOutField.Name,
        FarmLandCropsOutField.CropShortName,
        FarmLandCropsOutField.Area2d,
        FarmLandCropsOutField.ArcgisObjectId,
      ].join(","),
    });
  }

  async cropNamesList(farmName: string): Promise<IGeoFeatureLayerDto<ICropAttributes>> {
    return this.list({
      where: `farm='${farmName}'`,
      outFields: FarmLandCropsOutField.CropShortName,
      returnDistinctValues: true,
    });
  }
}

export const farmLandCropsGeoService = new FarmLandCropsGeoService();
