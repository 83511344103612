import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PATHS } from "../../../../constant";
import { ObservationsEditingForm } from "../observations-editing/observations-editing";

const ObservationsList = lazy(() => import("../observations-list/observations-list"));

export const Observations = (): JSX.Element => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index={true} element={<ObservationsList />} />
        <Route path={`${PATHS.EDIT_PAGE}/:id`} element={<ObservationsEditingForm />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </Suspense>
  );
};
