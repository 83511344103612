import { ICropTypeDto } from "../dtos/crop-type";

export class CropType {
  readonly id: string;
  name: string;
  basicHumidity?: number;
  basicImpurity?: number;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ICropTypeDto {
    return {
      id: this.id,
      name: this.name,
      basicHumidity: this.basicHumidity,
      basicImpurity: this.basicImpurity,
    };
  }

  updateFromDto(dto: ICropTypeDto): void {
    this.name = dto.name;
    this.basicHumidity = dto.basicHumidity;
    this.basicImpurity = dto.basicImpurity;
  }
}
