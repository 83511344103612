import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import "./app.css";
import { ProvideAuth } from "./authentication";
import { AppRouter } from "./router";
import { MuiTheme } from "./styles/mui-theme";

export function App(): JSX.Element {
  return (
    <ThemeProvider theme={MuiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <ProvideAuth>
          <AppRouter />
        </ProvideAuth>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
