export const DateTimeFormatter = (unixtime: number, time = false): string => {
  const date = new Date(Number(unixtime));
  let day: string | number = date.getDate();
  if (day < 10) day = "0" + day;

  let month: string | number = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let year: string | number = date.getFullYear();
  if (year < 10) year = "0" + year;

  let hour: string | number = date.getHours();
  if (hour < 10) hour = "0" + hour;

  let minutes: string | number = date.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;

  return time ? day + "." + month + "." + year + " " + hour + ":" + minutes : day + "." + month + "." + year;
};
