import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { IFarmLandsTechState } from "../shared/interfaces/farm-lands-tech-state";
import { FarmLandTech } from "../shared/models/farm-land-tech";
import { farmLandsService } from "../shared/services/farm-lands.service";

export const MODULE_NAME = "farmLandsTech";
export const farmLandsTechSlice = createSlice({
  name: MODULE_NAME,
  initialState: {
    isLoading: false,
    list: [],
    listCount: 0,
  } as IFarmLandsTechState,
  reducers: {
    setListAction(state, action): void {
      state.list = action.payload;
    },
    setListCountAction(state, action): void {
      state.listCount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFarmLandsTechAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmLandsTechAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmLandsTechAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const farmLandsTechReducer = farmLandsTechSlice.reducer;
export const { setListAction, setListCountAction } = farmLandsTechSlice.actions;

// async actions
export const fetchFarmLandsTechAction = createAsyncThunk<void, { id: string; filter: ILoopbackFilter }>(
  `${MODULE_NAME}/fetchFarmLandsTech`,
  async ({ id, filter }, { dispatch }) => {
    const count = await farmLandsService.techListCount(id, filter);
    const dtos = await farmLandsService.techList(id, filter);
    const models = dtos.map((dto) => {
      const model = new FarmLandTech();
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);
