import { Box, Grid, IconButton, ListItem } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo } from "react";
import { ArrayField, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFSelect } from "../../../../shared/components/react-hook-form-mui/select";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { TechOperationAssetEntityID } from "../../shared/enums/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { TechOperationAssetType } from "../../shared/models/tech-operation-assetType";
import { getAssetTypes } from "../../store/tech-operation-editing.selector";

interface IProps {
  idx: number;
  asset: Partial<ArrayField<ITechOperationAssetFormData, "id">>;
  isFirst: boolean;
  optionsLabel: string;
  noOptionsText: string;
  onRemove: (idx: number, assetId: string) => void;
}

// eslint-disable-next-line
export const TechOperationEditingAsset = (props: IProps): JSX.Element => {
  const assetTypes = useSelector(getAssetTypes);

  const rhfMethods = useFormContext();
  const watchFields = rhfMethods.watch();

  const options = useMemo(() => {
    return assetTypes.filter((item) => item.entityType === props.asset.entityId) || [];
  }, [assetTypes, props.asset]);

  const getOptionById = useCallback((id: string) => options.find((item) => item.entityId === id) || null, [options]);

  const units = useMemo(
    () => getOptionById(watchFields.assets?.[props.idx]?.entityType)?.units || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOptionById, props.idx, watchFields.assets?.[props.idx]?.entityType]
  );

  const handleRemoveAsset = useCallback(() => {
    props.onRemove(props.idx, props.asset._id || "");
  }, [props]);

  useEffect(() => {
    rhfMethods.setValue(`assets.${props.idx}.unit`, units[0] || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idx, units]);

  const onAssetTypeChange = useCallback(
    (val, onChange) => {
      rhfMethods.setValue(`assets.${props.idx}.name`, val?.name || "");
      rhfMethods.setValue(`assets.${props.idx}.assetTypeId`, val?.id || null);
      onChange(val?.entityId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.idx]
  );

  return (
    <ListItem>
      <RHFInputHidden name={`assets.${props.idx}._id`} defaultValue={props.asset._id} />
      <RHFInputHidden name={`assets.${props.idx}.entityId`} defaultValue={props.asset.entityId} />
      <RHFInputHidden name={`assets.${props.idx}.createdAt`} defaultValue={props.asset.createdAt} />
      <RHFInputHidden name={`assets.${props.idx}.assetTypeId`} defaultValue={props.asset.assetTypeId} />
      <RHFInputHidden name={`assets.${props.idx}.name`} defaultValue={props.asset.name} />

      <Grid container={true} spacing={2} alignItems="center">
        {!props.isFirst && (
          <Grid item={true}>
            <Box pt={2} m={-1}>
              <IconButton onClick={handleRemoveAsset}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Grid>
        )}

        <Grid item={true} xs={4}>
          <RHFAutocomplete<TechOperationAssetType>
            name={`assets.${props.idx}.entityType`}
            defaultValue={props.asset.entityType}
            rules={{ required: true }}
            renderValue={getOptionById}
            renderOnChange={onAssetTypeChange}
            AutocompleteProps={{
              disabled: !options.length,
              options: options,
              noOptionsText: props.noOptionsText,
            }}
            TextFieldProps={{
              label: props.optionsLabel,
            }}
          />
        </Grid>

        <Grid item={true} xs={2}>
          <RHFTextField
            name={`assets.${props.idx}.quantity`}
            defaultValue={props.asset.quantity?.toString()}
            rules={{ required: true }}
            TextFieldProps={{
              disabled: !watchFields.assets?.[props.idx]?.entityType,
              label: "Количество",
              type: "number",
            }}
          />
        </Grid>

        <Grid item={true} xs={2}>
          <RHFSelect
            name={`assets.${props.idx}.unit`}
            defaultValue={props.asset.unit || units[0] || ""}
            options={units}
            label="Ед. измерения"
            disabled={!watchFields.assets?.[props.idx]?.entityType}
          />
        </Grid>

        <Grid item={true} xs={true}>
          {props.asset.entityId === TechOperationAssetEntityID.SEED && (
            <RHFTextField
              name={`assets.${props.idx}.seedUnitWeight`}
              defaultValue={props.asset.seedUnitWeight?.toString()}
              rules={{ required: true }}
              TextFieldProps={{
                type: "number",
                label: "Масса 1000 семян (в граммах)",
              }}
            />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};
