import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import { deleteWeedsAction, fetchWeedsAction, getSelectedIds, setSearchText, setSelectedIdsAction } from "../../store";

const WeedList = lazy(() => import("../weeds-list/weeds-list"));

const WeedsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);

  const onDelete = useCallback(
    () => dispatch(deleteWeedsAction(selectedIds)).then(() => dispatch(fetchWeedsAction())),
    [selectedIds, dispatch]
  );

  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.WEEDS}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <WeedList />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default WeedsTab;
