import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { dynamicReducers } from "./reducers";

export const store = configureStore({
  reducer: dynamicReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // TODO: think about custom availability
      immutableCheck: false, // sometimes enable it for checking
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
