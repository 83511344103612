import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline as AddCircleOutlineIcon, Delete as DeleteIcon } from "@material-ui/icons";
import lodashGet from "lodash/get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import {
  ObservationViolationType,
  ObservationViolationTypeHuman as violationTypes,
} from "../../../../shared/enums/observation-violation-type";
import { IObservationViolation } from "../../../../shared/interface/observation-violation";
import { observationsService } from "../../shared/services/observations.service";

const violationDefaultValue = {
  problemType: null,
  name: null,
  problemId: null,
  rate: null,
};

export const ObservationViolationsList = (): JSX.Element => {
  const rhfMethods = useFormContext();
  const watchFields = rhfMethods.watch();

  const [violationsByTypes, setViolationsByTypes] = useState<Record<ObservationViolationType, IObservationViolation[]>>(
    {
      [ObservationViolationType.Weed]: [],
      [ObservationViolationType.Disease]: [],
      [ObservationViolationType.Pest]: [],
    }
  );

  useEffect(() => {
    (async () => {
      const weedViolations = await observationsService.getWeedViolationsByCropTypeId(watchFields.cropTypeId);
      const diseaseViolations = await observationsService.getDiseaseViolationsByCropTypeId(watchFields.cropTypeId);
      const pestViolations = await observationsService.getPestViolationsByCropTypeId(watchFields.cropTypeId);

      setViolationsByTypes({
        [ObservationViolationType.Weed]: weedViolations,
        [ObservationViolationType.Disease]: diseaseViolations,
        [ObservationViolationType.Pest]: pestViolations,
      });
    })();
  }, [watchFields.cropTypeId]);

  const {
    fields: violations,
    append: appendViolation,
    remove: removeViolation,
  } = useFieldArray({
    name: "violations",
    control: rhfMethods.control,
    keyName: "_uid",
  });

  const getItemById = useCallback((collection, id) => {
    return collection?.find((item) => item.id === id) || null;
  }, []);

  const isViolationAddingDisabled = useMemo(() => {
    let result = false;
    Object.keys(violationTypes).forEach((key) => {
      if (result || violationsByTypes[key]?.length) {
        result = true;
      }
    });

    return !result;
  }, [violationsByTypes]);

  const onViolationTypeChange = useCallback((idx, value, setViolationProblemType) => {
    setViolationProblemType(value);
    rhfMethods.setValue(`violations.${idx}.name`, null);
    rhfMethods.setValue(`violations.${idx}.problemId`, null);
    rhfMethods.setValue(`violations.${idx}.rate`, null);
    // eslint-disable-next-line
  }, []);

  const onViolationChange = useCallback((idx, value, setViolationProblemId) => {
    setViolationProblemId(value.id);
    rhfMethods.setValue(`violations.${idx}.name`, value?.name || "");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ListItem>
        <Typography variant={"h5"}>Нарушения</Typography>

        <Box clone={true} ml={2}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => appendViolation({ ...violationDefaultValue })}
            disabled={isViolationAddingDisabled}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Box>
      </ListItem>

      {isViolationAddingDisabled ||
        violations.map((violation, idx) => {
          const problemType = rhfMethods.getValues(`violations.${idx}.problemType`) as ObservationViolationType;

          return (
            <ListItem key={violation._uid}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.problemType`}
                    control={rhfMethods.control}
                    rules={{ required: true }}
                    defaultValue={violation.problemType}
                    render={({ onChange, value }) => (
                      <FormControl
                        fullWidth={true}
                        error={Boolean(lodashGet(rhfMethods.formState.errors, `violations.${idx}.problemType`))}
                      >
                        <InputLabel id={`violations.${idx}.problemType`}>Тип нарушения</InputLabel>
                        <Select
                          labelId={`violations.${idx}.problemType`}
                          value={value || ""}
                          onChange={(evt) => onViolationTypeChange(idx, evt.target.value, onChange)}
                        >
                          {Object.keys(violationTypes)
                            .filter((key) => violationsByTypes[key]?.length)
                            .map((key) => (
                              <MenuItem key={`violations.${idx}.problemType-${key}`} value={key}>
                                {violationTypes[key]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.name`}
                    control={rhfMethods.control}
                    defaultValue={violation.name}
                    render={({ value }) => <input type="hidden" value={value || ""} />}
                  />

                  <RHFAutocomplete<IObservationViolation>
                    name={`violations.${idx}.problemId`}
                    rules={{ required: true }}
                    defaultValue={violation.problemId}
                    renderValue={(value) => getItemById(violationsByTypes[problemType], value)}
                    renderOnChange={(value, onChange) => onViolationChange(idx, value, onChange)}
                    AutocompleteProps={{
                      disabled: !problemType,
                      options: problemType ? violationsByTypes[problemType] : [],
                      noOptionsText: "Нет возможных нарушений",
                    }}
                    TextFieldProps={{
                      label: "Нарушение",
                    }}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <RHFTextField
                    name={`violations.${idx}.rate`}
                    rules={{ required: true }}
                    defaultValue={violation.rate}
                    TextFieldProps={{
                      type: "number",
                      label: "Интенсивность",
                    }}
                  />
                </Grid>

                <Grid item={true}>
                  <IconButton onClick={() => removeViolation(idx)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
    </>
  );
};
