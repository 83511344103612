import { Collapse, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

import { AlertsProps } from "../../common-types";
import { alertStyles } from "./alerts-styles";

export const AlertComponent = (props: AlertsProps): JSX.Element => {
  const { type, text, isCloseButton, title, variant } = props;

  const [open, setOpen] = React.useState(true);

  const classes = alertStyles();

  return (
    <Collapse in={open}>
      <Alert
        variant={variant ? variant : undefined}
        severity={type}
        className={`${classes.alert} ${variant !== "filled" && classes[type]}`}
        action={
          isCloseButton && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </Alert>
    </Collapse>
  );
};
