import { SET_INFO_DATA } from "./info-data-actions";
import { INFO_DATA_INITIAL_STATE } from "./info-data-constants";

// eslint-disable-next-line
export const infoDataReducer = (state = INFO_DATA_INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INFO_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
