import React, { useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { useAuth } from "./authentication";
import { ProtectedRoute } from "./authentication/containers/protected-route/protected-route";
import { PATHS } from "./constant";
import { Catalogs } from "./pages/catalogs/catalogs";
import { Dashboard } from "./pages/dashboard/containers/dashboard/dashboard";
import { FarmLands } from "./pages/fields/containers/farm-lands/farm-lands";
import { Login } from "./pages/login";
import { MapComponent } from "./pages/map-page";
import { Observations } from "./pages/observations/containers/observations/observations";
import { TechOperations } from "./pages/tech-operations";
import { loadDictionariesAction } from "./reducers";
import { useAppDispatch } from "./store";

export const AppRouter = (): JSX.Element => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }
    dispatch(loadDictionariesAction());
  }, [auth, dispatch]);

  return (
    <Router>
      <Routes>
        <Route path={PATHS.LOGIN_PAGE} element={<Login />} />

        <Route path={PATHS.MAIN_PAGE} element={<ProtectedRoute redirectPath={PATHS.LOGIN_PAGE} />}>
          <Route path={`${PATHS.CATALOG_PAGE}/*`} element={<Catalogs />} />
          <Route path={`${PATHS.MAP_PAGE}/*`} element={<MapComponent />} />
          <Route path={`${PATHS.TECHOPERATIONS_PAGE}/*`} element={<TechOperations />} />
          <Route path={`${PATHS.FIELDS_PAGE}/*`} element={<FarmLands />} />
          <Route path={`${PATHS.OBSERVATIONS_PAGE}/*`} element={<Observations />} />
          <Route path={`${PATHS.DASHBOARD}/*`} element={<Dashboard />} />
          <Route index={true} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
          <Route path={"*"} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
        </Route>
      </Routes>
    </Router>
  );
};
