import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { isEditingPage } from "../../../shared/utils/is-editing-page";
import { ITechOperationAssetDto } from "../shared/dtos/tech-operation-asset.dto";
import { ITechOperationValue } from "../shared/dtos/tech-operation-param.dto";
import { ITechOperationDto } from "../shared/dtos/tech-operation.dto";
import { ITechOperationEditingState } from "../shared/interfaces/tech-operation-editing-state";
import { TechOperation } from "../shared/models/tech-operation";
import { TechOperationAsset } from "../shared/models/tech-operation-asset";
import { TechOperationAssetType } from "../shared/models/tech-operation-assetType";
import { techOperationsAssetsService } from "../shared/services/tech-operations-assets.service";
import { techOperationsService } from "../shared/services/tech-operations.service";

const initialState: ITechOperationEditingState = {
  techOperation: new TechOperation(),
  assets: [],
  assetTypes: [],
};

export const MODULE_NAME = "techOperationEditingPage";
export const techOperationEditingSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialState,
  reducers: {
    setTechOperationData(state, action: PayloadAction<TechOperation>): void {
      state.techOperation = action.payload;
    },
    setTechOperationAssetTypes(state, action: PayloadAction<TechOperationAssetType[]>): void {
      state.assetTypes = action.payload;
    },
    setTechOperationAssets(state, action: PayloadAction<TechOperationAsset[]>): void {
      state.assets = action.payload;
    },
    addTechOperationAsset(state, action: PayloadAction<TechOperationAsset>): void {
      state.assets = [...state.assets, action.payload];
    },
    updateTechOperationAsset(state, action: PayloadAction<TechOperationAsset>): void {
      state.assets = state.assets.map((asset) => {
        if (asset.id === action.payload.id) {
          return action.payload;
        }
        return asset;
      });
    },
    removeTechOperationAsset(state, action: PayloadAction<string>): void {
      state.assets = state.assets.filter((asset) => asset.id !== action.payload);
    },
    setTechOperationValues(state, action: PayloadAction<ITechOperationValue[]>): void {
      state.techOperation.TechOperationValues = action.payload;
    },
    updateTechOperationValues(state, action: PayloadAction<ITechOperationValue[]>): void {
      state.techOperation.TechOperationValues = action.payload;
    },
  },
});

export const techOperationEditingReducer = techOperationEditingSlice.reducer;
export const {
  setTechOperationData,
  setTechOperationAssetTypes,
  setTechOperationAssets,
  addTechOperationAsset,
  updateTechOperationAsset,
  removeTechOperationAsset,
  setTechOperationValues,
} = techOperationEditingSlice.actions;

export const fetchTechOperationByIdAction = createAsyncThunk<TechOperation, string>(
  `${MODULE_NAME}/fetchTechOperation`,
  async (techOperationId, { dispatch }) => {
    const dto = await techOperationsService.get(techOperationId);
    const model = new TechOperation(dto.id);
    model.updateFromDto(dto);

    dispatch(setTechOperationData(model));
    return model;
  }
);

export const addTechOperationAction = createAsyncThunk<TechOperation, ITechOperationDto>(
  `${MODULE_NAME}/addTechOperation`,
  async (data, { dispatch }) => {
    const dto = await techOperationsService.add(data);
    const newModel = new TechOperation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setTechOperationData(newModel));
    return newModel;
  }
);

export const updateTechOperationAction = createAsyncThunk<TechOperation, ITechOperationDto>(
  `${MODULE_NAME}/updateTechOperation`,
  async (data, { dispatch }) => {
    const dto = await techOperationsService.update(data);
    const newModel = new TechOperation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setTechOperationData(newModel));
    return newModel;
  }
);

export const fetchAssetTypesAction = createAsyncThunk<TechOperationAssetType[], void>(
  `${MODULE_NAME}/fetchTechOperationAssetTypes`,
  async (_, { dispatch }) => {
    const dtos = await techOperationsAssetsService.typesList();
    const assetTypes = dtos.map((dto) => {
      const assetType = new TechOperationAssetType();
      assetType.updateFromDto(dto);
      return assetType;
    });
    dispatch(setTechOperationAssetTypes(assetTypes));
    return assetTypes;
  }
);

export const fetchAssetsByTechOperationIdAction = createAsyncThunk<TechOperationAsset[], string>(
  `${MODULE_NAME}/fetchTechOperationAssets`,
  async (techOperationId, { dispatch }) => {
    let assets: TechOperationAsset[] = [];
    if (isEditingPage(techOperationId)) {
      const dtos = await techOperationsAssetsService.list({ where: { techOperationId } });
      assets = dtos.map((dto) => {
        const asset = new TechOperationAsset(dto.id);
        asset.updateFromDto(dto);
        return asset;
      });
      dispatch(setTechOperationAssets(assets));
    }
    return assets;
  }
);

export const addTechOperationAssetAction = createAsyncThunk<TechOperationAsset, ITechOperationAssetDto>(
  `${MODULE_NAME}/addTechOperationAsset`,
  async (data, { dispatch }) => {
    const dto = await techOperationsAssetsService.add(data);
    const newModel = new TechOperationAsset(dto.id);
    newModel.updateFromDto(dto);
    dispatch(addTechOperationAsset(newModel));
    return newModel;
  }
);

export const updateTechOperationAssetAction = createAsyncThunk<TechOperationAsset, ITechOperationAssetDto>(
  `${MODULE_NAME}/updateTechOperationAsset`,
  async (data, { dispatch }) => {
    const dto = await techOperationsAssetsService.update(data);
    const newModel = new TechOperationAsset(dto.id);
    newModel.updateFromDto(dto);
    dispatch(updateTechOperationAsset(newModel));
    return newModel;
  }
);

export const deleteTechOperationAssetAction = createAsyncThunk<void, string>(
  `${MODULE_NAME}/deleteTechOperationAsset`,
  async (assetId, { dispatch }) => {
    if (!assetId) {
      return;
    }
    const res = await techOperationsAssetsService.delete(assetId);
    dispatch(removeTechOperationAsset(assetId));
    return res;
  }
);
