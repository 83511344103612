import { Button, FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import React, { useCallback } from "react";

import { Permissions } from "../../authentication/containers/permissions/permissions";
import { Roles } from "../../shared/enums/role";
import { DialogWindowComponent } from "../alerts";
import { CATALOG_SECTIONS_INFO, FunctionTypeEnum } from "./catalog-tab-layout-constants";
import { CatalogTabLayoutProps } from "./catalog-tab-layout-types";
import { catalogTabpanelStyles } from "./catalog-tab-layout.style";

export const CatalogTabLayout = (props: CatalogTabLayoutProps): JSX.Element | null => {
  const { children, type, selectedCount, onCancel, onDelete, onSearch } = props;
  const classes = catalogTabpanelStyles();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectFunc, setSelectFunc] = React.useState(FunctionTypeEnum.select);
  const [searchText, setSearchText] = React.useState<string>("");

  const catalogTabInfo = CATALOG_SECTIONS_INFO[type];

  const onSubmit = useCallback((e)=>{
      e.preventDefault();
  }, []);

  const onSearchChange = useCallback(
    (text: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(text.target.value);
      if (onSearch) {
        onSearch(text.target.value);
      }
    },
    [onSearch]
  );

  const onSearchClear = useCallback(() => {
    setSearchText("");
    if (onSearch) {
      onSearch("");
    }
  }, [setSearchText, onSearch]);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return (
    <div className={classes.root}>
      <DialogWindowComponent
        title="Удаление"
        text={["Вы уверены, что хотите удалить объект/ы?"]}
        open={isModalOpen}
        onCloseWindow={closeModal}
        isCloseButton={true}
        closeButtonFunc={closeModal}
        btnActions={[
          {
            textBtn: "Нет",
            colorBtn: "primary",
            variantBtn: "contained",
            funcBtn: closeModal,
          },
          {
            textBtn: "Удалить",
            colorBtn: "error",
            variantBtn: "outlined",
            funcBtn: () => {
              onDelete();
              closeModal();
            },
          },
        ]}
      />

      <div className={classes.manualHeader}>{catalogTabInfo.name}</div>
      <form className={classes.inputSearch} noValidate autoComplete="off" onSubmit={onSubmit}>
        {onSearch && (
          <TextField
            id="outlined-basic"
            variant="outlined"
            inputProps={{ "aria-label": "description" }}
            label="Найти"
            onChange={onSearchChange}
            size="small"
            value={searchText}
            style={{
              minWidth: "420px",
            }}
            InputProps={{
              endAdornment: [
                searchText.length > 0 && (
                  <CloseIcon key="closeIcon" onClick={onSearchClear} style={{ cursor: "pointer" }} />
                ),
                <SearchIcon key="searchIcon" />,
              ],
            }}
          />
        )}

        {/* {isAllowedToEdit && (
          <Link
            to={PATHS.CATALOG_PAGE + PATHS.EDIT_PAGE + PATHS.EDIT_NEW_PAGE}
            className="link"
            style={{
              whiteSpace: "nowrap",
              width: "auto",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <AddCircleOutlineIcon
                  color="secondary"
                  style={{
                    height: "30px",
                    width: "30px",
                  }}
                />
              }
              onClick={() => null}
            >
              {catalogTabInfo.btnAddName}
            </Button>
          </Link>
        )} */}
      </form>

      <div className={classes.manualBody}>{children}</div>
      <div className={classes.manualFooter}>
        Выбрано: {selectedCount}
        <FormControl style={{ padding: "0 30px", width: 250 }}>
          <Select defaultValue="select" onChange={(event) => setSelectFunc(event.target.value as FunctionTypeEnum)}>
            <MenuItem key="select" value={"select"}>
              Выберите действие
            </MenuItem>
            <Permissions some={[Roles.admin, Roles.manager]}>
              <MenuItem key="delete" value={"delete"} style={{ color: "#f44336" }}>
                Удалить
              </MenuItem>
            </Permissions>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={!(selectFunc === FunctionTypeEnum.delete && selectedCount > 0)}
          onClick={openModal}
        >
          Применить
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ border: "3px solid #709d4b", margin: "0 10px" }}
          onClick={onCancel}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};
