import { createContext, useContext } from "react";

import { AuthContextTypes } from "../common-types";

export const authContext = createContext<AuthContextTypes>({
  login: async () => false,
  // eslint-disable-next-line
  logout: () => {},
  isAuthenticated: null,
  userHasAuthenticated: () => null,
});

// eslint-disable-next-line
export const useAuth = () => {
  return useContext(authContext);
};
