import { IFilter } from "../../../../shared/interface/filter";
import { BaseService } from "../../../../shared/services/base.service";
import { ICropTypeDto } from "../dtos/crop-type.dto";

// TODO: remove this service in favor of CropTypes module (it already exists)
class CropTypesService extends BaseService<ICropTypeDto, Partial<IFilter>> {
  private path = "CropTypes";

  async cropTypeColorsList(): Promise<ICropTypeDto[]> {
    return this.fetch<ICropTypeDto[]>(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          fields: ["name", "color"],
        },
      },
    });
  }

  // eslint-disable-next-line
  list(filter: Partial<IFilter>, include: any): Promise<ICropTypeDto[]> {
    throw new Error("Method not implemented.");
  }

  get(id: string): Promise<ICropTypeDto> {
    throw new Error("Method not implemented.");
  }
}

export const cropTypesService = new CropTypesService();
